import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import logo from '../../images/logo_new.png';
import {styled} from "@mui/material/styles";
import {Link, Tab, Tabs} from "@mui/material";
import Divider from "@mui/material/Divider";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Logout} from "@mui/icons-material";
import UserIcon from "../../icons/user-icon";
import {useUser} from "../../auth/private-route";
import Auth2 from "../../auth/Auth2";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
const pages = [
    {
        label: 'Acasa',
        path: '/app/home'
    },
    {
        label: 'Cereri',
        path: '/app/requests'
    }
];

const StylizedAppBar = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '72px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    backgroundColor: "#FFFFFF",
    borderBottomColor: "#E7E7EF",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid"
}));


function ResponsiveAppBar({}) {
    const user = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = React.useState('/app/home');
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    useEffect(() => {
        let activeTab = "";
        pages.forEach(page => {
            if(location.pathname.indexOf(page.path) !== -1) {
                activeTab = page.path;
            }
        });
        setActiveTab(activeTab);
    }, [location.pathname])

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (path) => {
        if (path) {
            navigate(path);
        }
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    function handleChange(a, value) {
        navigate(value);
    }

    const goToAccount = () => {
        navigate("/app/account");
    }

    function signOut() {
        Auth2.signOut().then((resp) => {
            navigate("/auth/login");
        })
        .catch((err) => {
           console.log("Error signing out", err);
        });
    }

    return (
        <StylizedAppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link onClick={() => navigate("/app/home")}><img src={logo} alt="TedConect logo" /></Link>
                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        <Tabs
                            value={activeTab || ""}
                            onChange={handleChange}
                            indicatorColor="primary"
                            TabIndicatorProps={{
                                style: {
                                    height: "3px"
                                },
                            }}
                            aria-label="secondary tabs example"
                            sx={{
                                marginLeft: '40px'
                            }}
                        >
                            {pages.map((page) => (
                                <Tab
                                    value={page.path}
                                    label={page.label}
                                    key={page.path}
                                    sx={{
                                        lineHeight: '47px',
                                        textTransform: 'capitalize'
                                    }}/>

                            ))}
                        </Tabs>
                    </Box>

                    <Box sx={{ flexGrow: 1 }}>

                    </Box>
                    <Box sx={{
                        flexGrow: 0,
                        gap: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        fontWeight: 600,
                        marginLeft: 1
                    }}>
                        <Box>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt={user.firstName + " " + user.lastName} src={axios.defaults.baseURL.replace(/\/$/, "") + '/avatar/' + user.id} />
                            </IconButton>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem key="profile" onClick={goToAccount}>
                                    <ListItemIcon>
                                        <UserIcon />
                                    </ListItemIcon>
                                    Contul meu
                                </MenuItem>
                                <Divider />
                                <MenuItem key="logout" onClick={signOut}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Deconectare
                                </MenuItem>
                            </Menu>
                        </Box>
                        <Box sx={{display: { xs: 'none', md: 'flex' } }}>
                            {user.firstName} {user.lastName}
                        </Box>

                        <Divider sx={{display: { xs: 'flex', md: 'none' } }} orientation="vertical" variant="middle" flexItem />
                        <Box sx={{display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                                sx={{
                                    paddingLeft: '0px'
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page.path} onClick={() => handleCloseNavMenu(page.path)}>
                                        <Typography textAlign="center">{page.label}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Box>

                </Toolbar>
            </Container>
        </StylizedAppBar>
    );
}
export default ResponsiveAppBar;