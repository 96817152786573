import {SvgIcon} from "@mui/material";

function LoadingIcon(props) {
    return (
        <SvgIcon {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M30 5.88574C31.3807 5.88574 32.5 7.00503 32.5 8.38574V13.3857C32.5 14.7665 31.3807 15.8857 30 15.8857C28.6193 15.8857 27.5 14.7665 27.5 13.3857V8.38574C27.5 7.00503 28.6193 5.88574 30 5.88574Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M30 45.8857C31.3807 45.8857 32.5 47.005 32.5 48.3857V53.3857C32.5 54.7665 31.3807 55.8857 30 55.8857C28.6193 55.8857 27.5 54.7665 27.5 53.3857V48.3857C27.5 47.005 28.6193 45.8857 30 45.8857Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.3222 13.2078C13.2985 12.2315 14.8814 12.2315 15.8577 13.2078L19.3933 16.7434C20.3696 17.7197 20.3696 19.3026 19.3933 20.2789C18.417 21.2552 16.8341 21.2552 15.8577 20.2789L12.3222 16.7434C11.3459 15.767 11.3459 14.1841 12.3222 13.2078Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40.6066 41.492C41.5829 40.5157 43.1659 40.5157 44.1422 41.492L47.6777 45.0275C48.654 46.0038 48.654 47.5868 47.6777 48.5631C46.7014 49.5394 45.1185 49.5394 44.1422 48.5631L40.6066 45.0275C39.6303 44.0512 39.6303 42.4683 40.6066 41.492Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5 30.8857C5 29.505 6.11929 28.3857 7.5 28.3857L12.5 28.3857C13.8807 28.3857 15 29.505 15 30.8857C15 32.2665 13.8807 33.3857 12.5 33.3857L7.5 33.3857C6.11929 33.3857 5 32.2665 5 30.8857Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M45 30.8857C45 29.505 46.1193 28.3857 47.5 28.3857L52.5 28.3857C53.8807 28.3857 55 29.505 55 30.8857C55 32.2665 53.8807 33.3857 52.5 33.3857L47.5 33.3857C46.1193 33.3857 45 32.2665 45 30.8857Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.3223 48.5633C11.346 47.587 11.346 46.0041 12.3223 45.0278L15.8579 41.4922C16.8342 40.5159 18.4171 40.5159 19.3934 41.4922C20.3697 42.4685 20.3697 44.0514 19.3934 45.0278L15.8579 48.5633C14.8815 49.5396 13.2986 49.5396 12.3223 48.5633Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40.6065 20.2791C39.6302 19.3028 39.6302 17.7199 40.6065 16.7436L44.142 13.208C45.1183 12.2317 46.7013 12.2317 47.6776 13.208C48.6539 14.1844 48.6539 15.7673 47.6776 16.7436L44.142 20.2791C43.1657 21.2554 41.5828 21.2554 40.6065 20.2791Z" />
        </SvgIcon>
    )
}

export default LoadingIcon;