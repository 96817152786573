import {Link, useLocation, useNavigate} from "react-router-dom";
import {IconButton, MenuItem, Stack} from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import logo from "../../images/logo_new.png";
import Button from "@mui/material/Button";
import MenuIcon from "../../icons/menu-icon";
import {useEffect, useState} from "react";
import Menu from "@mui/material/Menu";
import UserIcon from "../../icons/user-icon";

import './navbar.css';

const links = [
    {
        path: '/',
        label: 'Acasa',
        active: false
    },
    {
        path: '/pentru-furnizori',
        label: 'Pentru furnizori',
        active: false
    },
    {
        path: '/contact',
        label: 'Contact',
        active: false
    }
]

function WebsiteNavbar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleMenuButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const path = location.pathname;
        setCurrentPath(path);
    }, [location]);

    function goTo(path) {
        navigate(path);
        handleClose();
    }

    function goToLogin() {
        navigate("/app/home");
    }

    function goToHome() {
        navigate("/");
    }

    return (
        <Container>
            <Box sx = {{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <Box sx = {{ cursor: "pointer"}} onClick={goToHome}>
                    <img src={logo} alt="TedConect logo" />
                </Box>
                <Box sx = {{
                    display: {
                        xs: 'none',
                        md: 'block'
                    }
                }}>
                    <Stack direction="row" spacing={3} alignItems="center" className="website-menu">
                        {links.map(link => <Link className={link.path === currentPath ? "selected": ""} to={link.path}>{link.label}</Link>)}

                        <Link to="/app/home">
                            <Button
                                variant="outlined"
                                startIcon={<UserIcon />}
                            >
                                Contul Meu
                            </Button>
                        </Link>
                    </Stack>
                </Box>
                <Box sx = {{
                    display: {
                        xs: 'block',
                        md: 'none'
                    }
                }}>
                    <IconButton
                        aria-label="menu"
                        size="large"
                        onClick={handleMenuButtonClick}
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    PaperProps={{
                        style: {
                            width: '100%',
                            padding: '8px'
                        }
                    }}
                >
                    <MenuItem onClick={() => goTo('/')}>
                        Acasa
                    </MenuItem>
                    <MenuItem onClick={() => goTo('/pentru-furnizori')}>
                        Pentru Furnizori
                    </MenuItem>
                    <MenuItem onClick={() => goTo('/contact')}>
                        Contact
                    </MenuItem>
                    <Box p={1}>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={goToLogin}
                            startIcon={<UserIcon />}
                        >
                            Contul Meu
                        </Button>
                    </Box>

                </Menu>
            </Box>
        </Container>
    )
}

export default WebsiteNavbar;