//create a class to handle authentication
import axios from "axios";

export default class Auth2 {
    //create a method to handle sign in
    static async signIn(email, password) {
        console.log('login...', email, password);
        try {
            const registerResponse = await axios.post('/login', {email, password}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            console.log('checking if user is verified');
            try {
                const currentUserResponse = await axios.get('/api/user');
            } catch (error) {
                console.log('checked user error', error);
                if (error.response && error.response.status === 403) {
                    console.log('user is not verified...');
                    await Auth2.signOut();
                    throw error;
                }
            }
            return;
        } catch (error) {
            console.log('error2', error);
            //if status is 419, it means that the csrf token is missing
            if (error.response && error.response.status === 419) {
                console.log('csrf token is missing');
                //get the csrf token
                const csrfCookie = await axios.get('/sanctum/csrf-cookie');
            } else if (error.response && [301, 302].includes(error.response.status)) {
                return;
            } else {
                throw error;
            }
        }

        //retry the register request
        const registerResponse = await axios.post('/login', {email, password}, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
        console.log('checking if user is verified');
        try {
            const currentUserResponse = await axios.get('/api/user');
        } catch (error) {
            console.log('checked user error', error);
            if (error.response && error.response.status === 403) {
                console.log('user is not verified...');
                await Auth2.signOut();
                throw error;
            }
        }

    }

    static async signOut() {
        console.log('logout...');
        sessionStorage.removeItem('current-user');
        try {
            return axios.post('/logout', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });
            return;
        } catch (error) {
            console.log('error', error);
            //if status is 419, it means that the csrf token is missing
            if (error.response && error.response.status === 419) {
                console.log('csrf token is missing');
                //get the csrf token
                const csrfCookie = await axios.get('/sanctum/csrf-cookie');
            } else {
                throw error;
            }
        }

        //retry the register request
        const registerResponse = await axios.post('/logout', {}, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
    }

    static async currentAuthenticatedUser() {
        console.log('get logged in user...');
        try {
            const currentUserResponse = await axios.get('/api/user');
            return currentUserResponse;
        } catch (error) {
            console.log('error', error);
            //if status is 419, it means that the csrf token is missing
            if (error.response && error.response.status === 419) {
                console.log('csrf token is missing');
                //get the csrf token
                const csrfCookie = await axios.get('/sanctum/csrf-cookie');
            } else {
                throw error;
            }
        }

        //retry the register request
        return await axios.get('/api/user');
    }

    static async register(data) {
        console.log('register...', data);
        try {
            const registerResponse = await axios.post('/register', data);
            return;
        } catch (error) {
            console.log('error', error);
            //if status is 419, it means that the csrf token is missing
            if (error.response && error.response.status === 419) {
                console.log('csrf token is missing');
                //get the csrf token
                const csrfCookie = await axios.get('/sanctum/csrf-cookie');
            } else {
                throw error;
            }
        }

        //retry the register request
        const registerResponse = await axios.post('/register', data);
    }

    static async forgotPassword(email) {
        try {
            const registerResponse = await axios.post('/forgot-password', {email});
            return registerResponse;
        } catch (error) {
            console.log('error', error);
            //if status is 419, it means that the csrf token is missing
            if (error.response && error.response.status === 419) {
                console.log('csrf token is missing');
                //get the csrf token
                const csrfCookie = await axios.get('/sanctum/csrf-cookie');
            } else {
                throw error;
            }
        }

        //retry the register request
        const registerResponse = await axios.post('/forgot-password', {email});
        return registerResponse;
    }

    static async resetPassword(email, password, passwordConfirmation, token) {
        const data = {
            email,
            password,
            password_confirmation: passwordConfirmation,
            token
        };

        try {
            const registerResponse = await axios.post('/reset-password', data);
            return registerResponse;
        } catch (error) {
            console.log('error', error);
            //if status is 419, it means that the csrf token is missing
            if (error.response && error.response.status === 419) {
                console.log('csrf token is missing');
                //get the csrf token
                const csrfCookie = await axios.get('/sanctum/csrf-cookie');
            } else {
                throw error;
            }
        }

        //retry the register request
        const registerResponse = await axios.post('/reset-password', data);
        return registerResponse;
    }
}