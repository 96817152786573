import {SvgIcon} from "@mui/material";

function CrayonSmallUpload(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 18 18">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9016 2.8553C11.7803 1.97662 13.2049 1.97662 14.0836 2.8553L15.1443 3.91596C16.023 4.79464 16.023 6.21926 15.1443 7.09794L7.3499 14.8923C7.2452 14.997 7.11185 15.0684 6.96665 15.0974L2.98918 15.8929C2.46439 15.9979 2.0017 15.5352 2.10666 15.0104L2.90215 11.0329C2.93119 10.8877 3.00256 10.7544 3.10726 10.6497L10.9016 2.8553ZM13.023 3.91596L14.0836 4.97662C14.3765 5.26951 14.3765 5.74439 14.0836 6.03728L13.023 7.09794L10.9016 4.97662L11.9623 3.91596C12.2552 3.62307 12.7301 3.62307 13.023 3.91596ZM9.84097 6.03728L4.32849 11.5498L3.79816 14.2014L6.44981 13.6711L11.9623 8.1586L9.84097 6.03728Z" fill="white"/>
        </SvgIcon>
    )
}

export default CrayonSmallUpload;