export const listRequestStatuses = /* GraphQL */ `
    query ListRequestStatuses(
        $type: String
    ) {
        listRequestStatuses(type: $type) {
            id
            code
            label
            duration
        }
    }
`;