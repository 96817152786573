import {Backdrop, keyframes, Typography} from "@mui/material";
import LoadingIcon from "../icons/loading-icon";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

function Loading() {
    return (
        <Backdrop open={true} sx = {{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "shades.gray500",
            backgroundColor: "#E5E5E5",
            flexDirection: "column"
        }}>
            <div><LoadingIcon inheritViewBox={true} sx = {{ width: 61, height: 61, animation: `${spin} 1s infinite ease`, display: "inline-block"}} /></div>
            <Typography component="div" variant="h6" color="text.black">Se incarca</Typography>
        </Backdrop>
    )
}

export default Loading;