import React, {useEffect, useState, createContext, useContext} from 'react';
import { Navigate } from 'react-router-dom';
import AppLayout from "../components/AppLayout";
import Loading from "../components/loading";
import Auth2 from "./Auth2";

export const UserContext = createContext({});

export function useUser() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within the UserProvider");
    }
    return context;
}

const PrivateRoute = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(undefined);
    const [currentUser, setCurrentUser] = useState({});

    const getCurrentUser = () => {
        let currentUserSesssionStorage = sessionStorage.getItem('current-user');
        if (currentUserSesssionStorage) {
            setCurrentUser(JSON.parse(currentUserSesssionStorage));
            setIsLoggedIn(true);
            return;
        }

        Auth2.currentAuthenticatedUser()
            .then((userData) => {
                sessionStorage.setItem('current-user', JSON.stringify(userData.data));
                setCurrentUser(userData.data);
                setIsLoggedIn(true);
            })
            .catch((err) => {
                console.log('Error', err);
                setIsLoggedIn(false)
            });
    }

    useEffect(() => {
        getCurrentUser();
    }, []);

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    if (isLoggedIn === false) {
        return <Navigate to="/auth/login" />;
    }

    return currentUser.email ? <UserContext.Provider value={currentUser}>
        <AppLayout user={currentUser}/>
    </UserContext.Provider> : <Loading/>;
}
export default PrivateRoute;