import Box from "@mui/material/Box";

function Flex(props) {
    return (
        <Box sx = {{
            display: "flex",
            flexDirection: props.direction ? props.direction : "column",
            alignItems: props.alignItems ? props.alignItems : "",
            justifyContent: props.justifyContent ? props.justifyContent: "",
            gap: props.gap ? props.gap : "",
           ...props.sx
        }}>
            {props.children}
        </Box>
    )
}

export default Flex;