import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import logo from "../images/logo_new.png";
import * as React from "react";

function Confirm() {

    return (
        <Box
            component="form"
            sx = {{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "400px"
            }}
        >
            <Box sx={{
                textAlign: "left",
                width: "100%",
                marginBottom: 4
            }}>
                <img src={logo} alt="TedConect logo" />
            </Box>
            <Typography
                variant="h2"
                sx = {{
                    marginBottom: 1
                }}
            >
                Confirmare cont
            </Typography>
            <Typography
                sx = {{
                    marginBottom: 4
                }}
            >
                <p>Te rugăm să confirmi adresa ta de e-mail pentru a putea finaliza înregistrarea/activarea contului tău. Este important să confirmi adresa de e-mail pentru a avea acces la toate funcționalitățile și beneficiile contului tău.</p>

                <p>Te rugăm să accesezi adresa ta de e-mail și să cauți un e-mail de confirmare de la noi. Dacă nu găsești e-mailul în căsuța ta de primire, te rugăm să verifici folderul de spam sau junk.</p>

                <p>Dacă ai întâmpinat dificultăți sau ai întrebări, nu ezita să ne contactezi. Suntem aici pentru a te ajuta!</p>
            </Typography>
        </Box>
    )
}

export default Confirm;