import {SvgIcon} from "@mui/material";

function Instagram(props) {
    return (
        <SvgIcon
            {...props}
            width={props.width ? props.widht : "24"}
            height={props.height ? props.height : "25"}
            viewBox={props.viewport ? props.viewport : "0 0 24 25"}
        >
            <path
                d="M11.9999 7.51318C9.17733 7.51318 6.83862 9.81157 6.83862 12.6745C6.83862 15.5374 9.13701 17.8358 11.9999 17.8358C14.8628 17.8358 17.1612 15.4971 17.1612 12.6745C17.1612 9.85189 14.8225 7.51318 11.9999 7.51318ZM11.9999 15.9809C10.1854 15.9809 8.69346 14.489 8.69346 12.6745C8.69346 10.86 10.1854 9.36802 11.9999 9.36802C13.8144 9.36802 15.3064 10.86 15.3064 12.6745C15.3064 14.489 13.8144 15.9809 11.9999 15.9809Z"
                fill="#87879A"/>
            <path
                d="M17.3627 8.56185C18.0085 8.56185 18.5321 8.03832 18.5321 7.3925C18.5321 6.74668 18.0085 6.22314 17.3627 6.22314C16.7169 6.22314 16.1934 6.74668 16.1934 7.3925C16.1934 8.03832 16.7169 8.56185 17.3627 8.56185Z"
                fill="#87879A"/>
            <path
                d="M20.3871 4.36807C19.3387 3.27936 17.8468 2.71484 16.1532 2.71484H7.84677C4.33871 2.71484 2 5.05355 2 8.56162V16.8277C2 18.5616 2.56452 20.0536 3.69355 21.1423C4.78226 22.1907 6.23387 22.7148 7.8871 22.7148H16.1129C17.8468 22.7148 19.2984 22.1503 20.3468 21.1423C21.4355 20.0939 22 18.6019 22 16.8681V8.56162C22 6.86807 21.4355 5.41646 20.3871 4.36807ZM20.2258 16.8681C20.2258 18.1181 19.7823 19.1261 19.0565 19.8116C18.3306 20.4971 17.3226 20.86 16.1129 20.86H7.8871C6.67742 20.86 5.66935 20.4971 4.94355 19.8116C4.21774 19.0858 3.85484 18.0777 3.85484 16.8277V8.56162C3.85484 7.35194 4.21774 6.34388 4.94355 5.61807C5.62903 4.93259 6.67742 4.56968 7.8871 4.56968H16.1935C17.4032 4.56968 18.4113 4.93259 19.1371 5.65839C19.8226 6.3842 20.2258 7.39226 20.2258 8.56162V16.8681Z"
                fill="#87879A"/>
        </SvgIcon>
    )
}

export default Instagram;