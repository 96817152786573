import {Avatar, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Typography} from "@mui/material";
import * as yup from "yup";
import {useFormik} from "formik";
import {StylizedInput} from "../../components/StylizedComponents";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Auth, Hub} from "aws-amplify";
import showToast from "../../utils/toast";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import AvatarUploader from "./avatar-uploader";
import {useUser} from "../../auth/private-route";

const validationSchema = yup.object({
    firstName: yup
        .string()
        .required("Completeaza prenumele"),
    lastName: yup
        .string()
        .required("Completeaza numele"),
    email: yup
        .string()
        .required("Completeaza email-ul")
});

const validationSchemaConfirmEmail = yup.object({
    code: yup.string()
        .required("Completeaza codul")
});

function Account(props) {
    const user = useUser();
    const [loading, setLoading] = useState(false);
    const [loadingConfirmEmail, setLoadingConfirmEmail] = useState(false);
    const [openConfirmEmailDialog, setOpenConfirmEmailDialog] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            Auth.updateUserAttributes(props.user, {
                "custom:firstName": formik.values.firstName,
                "custom:lastName": formik.values.lastName,
                email: formik.values.email
            }).then(response => {
                console.log("response", response);
                showToast("Datele au fost modificate cu success", true);
                setLoading(false)
            }).catch(err => {
                console.log("err", err);
                showToast("A aparut o eroare.", false);
                setLoading(false);
            });
        }
    })

    const confirmEmailFormik = useFormik({
     initialValues: {},
     validationSchema: validationSchemaConfirmEmail,
     onSubmit: (values) => {
         setLoadingConfirmEmail(true);
         Auth.verifyCurrentUserAttributeSubmit('email', confirmEmailFormik.values.code).then(response => {
             console.log("response", response);
             showToast("Email-ul a fost confirmat cu succes", true);
             setLoadingConfirmEmail(false);
             setOpenConfirmEmailDialog(false);
             Hub.dispatch('RefreshUserDetails', {});
         }).catch(err => {
             console.log("err", err);
             showToast("A aparut o eroare.", false);
             setLoadingConfirmEmail(false);
         });
     }
    })



    useEffect(() => {
        Hub.listen('auth', ({payload}) => {

            console.log('asdddddd', payload);

            if (payload.event === 'updateUserAttributes') {
                const resultObject = payload.data;
                //check if email was updated
                if (resultObject['email'] && resultObject['email'].isUpdated === false) {
                    setOpenConfirmEmailDialog(true);
                }

                console.log('update data', resultObject);
            }
        });
    }, []);

    const handleSubmit = (e) => {
        formik.handleSubmit(e);
        return false;
    };

    const handleSubmitConfirmEmail = (e) => {
        confirmEmailFormik.handleSubmit(e);
        return false;
    };

    const handleConfirmEmailClose = () => {
        setOpenConfirmEmailDialog(false);
    };

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx = {{
                maxWidth: "600px",
                margin: "auto"
            }}>
                <AvatarUploader />
                <Card sx = {{
                   marginBottom: 2
                }}>
                    <CardContent>
                        <FormControl
                            variant="standard"
                            sx = {{
                                marginBottom: 2,
                                width: "100%"
                            }}
                        >
                            <FormLabel htmlFor="my-account-first-name">
                                <Typography variant="caption2">
                                    Prenume
                                </Typography>
                            </FormLabel>
                            <StylizedInput
                                placeholder="Completeaza prenumele"
                                id="my-account-first-name"
                                name="firstName"
                                value={formik.values.firstName || ""}
                                onChange={formik.handleChange}
                                error={(formik.touched.firstName || formik.submitCount > 0) && Boolean(formik.errors.firstName)}
                                helperText={(formik.touched.firstName || formik.submitCount > 0) && formik.errors.firstName}
                            />
                        </FormControl>
                        <FormControl
                            variant="standard"
                            sx = {{
                                marginBottom: 2,
                                width: "100%"
                            }}
                        >
                            <FormLabel htmlFor="my-account-last-name">
                                <Typography variant="caption2">
                                    Nume
                                </Typography>
                            </FormLabel>
                            <StylizedInput
                                placeholder="Completeaza numele"
                                id="my-account-last-name"
                                name="lastName"
                                value={formik.values.lastName || ""}
                                onChange={formik.handleChange}
                                error={(formik.touched.lastName || formik.submitCount > 0) && Boolean(formik.errors.lastName)}
                                helperText={(formik.touched.lastName || formik.submitCount > 0) && formik.errors.lastName}
                            />
                        </FormControl>
                        <FormControl
                            variant="standard"
                            sx = {{
                                marginBottom: 2,
                                width: "100%"
                            }}
                        >
                            <FormLabel htmlFor="my-account-email">
                                <Typography variant="caption2">
                                    Email
                                </Typography>
                            </FormLabel>
                            <StylizedInput
                                placeholder="Completeaza email-ul"
                                id="my-account-email"
                                name="email"
                                value={formik.values.email || ""}
                                onChange={formik.handleChange}
                                error={(formik.touched.email || formik.submitCount > 0) && Boolean(formik.errors.email)}
                                helperText={(formik.touched.email || formik.submitCount > 0) && formik.errors.email}
                            />
                        </FormControl>
                    </CardContent>
                </Card>
                <Box sx={{ textAlign: "center" }}>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={loading}
                    >
                        Salveaza
                    </LoadingButton>
                </Box>
            </Box>
            <Dialog open={openConfirmEmailDialog}>
                <Box
                    component="form"
                    onSubmit={handleSubmitConfirmEmail}
                >

                    <DialogTitle>
                        Confirma emailul
                    </DialogTitle>
                    <DialogContent>
                        <Box sx = {{ marginBottom: 2}}>
                            <Typography  variant="paragraph1"  sx = {{ marginBottom: 2 }}>
                                Completeaza codul de verificare primit pe email
                            </Typography>
                        </Box>

                        <FormControl
                            variant="standard"
                            sx = {{
                                marginBottom: 2,
                                width: "100%"
                            }}
                            required
                        >
                            <FormLabel htmlFor="confirm-email-code">
                                <Typography variant="caption2">
                                    Cod
                                </Typography>
                            </FormLabel>
                            <StylizedInput
                                placeholder="Completeaza codul"
                                id="confirm-email-code"
                                name="code"
                                value={confirmEmailFormik.values.code || ""}
                                onChange={confirmEmailFormik.handleChange}
                                error={(confirmEmailFormik.touched.code || confirmEmailFormik.submitCount > 0) && Boolean(confirmEmailFormik.errors.code)}
                                helperText={(confirmEmailFormik.touched.code || confirmEmailFormik.submitCount > 0) && confirmEmailFormik.errors.code}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmEmailClose}>Anuleaza</Button>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={loadingConfirmEmail}
                        >
                            Confirma
                        </LoadingButton>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default Account;