import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {styled} from "@mui/material/styles";
import {MenuItem} from "@mui/material";
import Menu from "@mui/material/Menu";
import showToast from "../../utils/toast";
import {useUser} from "../../auth/private-route";
import API2 from "../../graphql/laravel/API2";
import {updateRequest} from "../../graphql/laravel/UpdateRequest";
import {createEvent} from "../../graphql/laravel/CreateEvent";
import {listCitiesByArea} from "../../graphql/laravel/ListCitiesByArea";
import {listRequestStatuses} from "../../graphql/laravel/ListRequestStatuses";

const StatusButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.black,
    border: "1px solid #E7E7EF",
    '&:focus': {
        borderColor: theme.palette.primary.main
    }
}));

function ViewRequestSupplierBar({request, onStatusChange}) {
    const user = useUser();
    const [status, setStatus] = useState();
    const [statusList, setStatusList] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onStatusSelect = (status) => {
        console.log(status, request.id);
        setStatus(status);
        handleClose();
        API2.graphql(
            "UpdateRequest",
            updateRequest,
            {
                id: parseInt(request.id),
                input: {
                    statusId: status.id
                }
            }
        ).then(result => {
            console.log('res', result);
            showToast("Statusul a fost salvat cu success!", true);
            handleClose(true);

            API2.graphql("CreateEvent", createEvent,
                         {
                             input: {
                                 eventType: "STATUS_CHANGE_TO_" + status.code,
                                 requestID: request.id,
                             }
            }).then(createEventResult => {
                window.location.reload();
            }).catch(err => {
                console.log('err creating event', err);
            })
        }).catch(err => {
            console.log('err', err);
            showToast("Eroare in actualizarea statusului!", false);
        });
    }

    useEffect(() => {
        if (request) {
            setStatus(request.status);

            API2.graphql(
                "ListRequestStatuses",
                listRequestStatuses,
                {
                    type:  request.alreadyHasATR === "NO" ? "ATR" : "EXECUTION"
                },
            ).then(requestStatusesResult => {
                console.log('request statuses', requestStatusesResult);
                setStatusList(requestStatusesResult.data.listRequestStatuses);
            });
        }
    }, [request]);

    if (!request) {
        return <></>;
    }

    return (
        <Box
            sx = {{
                backgroundColor: "#FFFFFF",
                borderBottomColor: "#E7E7EF",
                borderBottomWidth: "1px",
                borderBottomStyle: "solid"
            }}
        >
            <Container
                sx = {{
                    height: {
                        xs: "88px",
                        md: "96px"
                    },
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundSize: {
                        xs: 'auto 64px',
                        md: '22%'
                    }
                }}
            >
                <Typography component="div" variant="h3">Detalii Cerere</Typography>
                <Box>
                    <StatusButton
                        aria-controls={open ? 'request-status-picker' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDown />}
                    >
                        <Typography variant="paragraph1">Etapa Proiect: <strong>{statusList.find(statusIter => statusIter.id === status.id)?.label}</strong></Typography>
                    </StatusButton>
                    <Menu
                        id="request-status-picker"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        {statusList.map(statusIter => {
                            return <MenuItem key={"stauts-" + statusIter.code} onClick={() => onStatusSelect(statusIter)} disableRipple>
                                    {statusIter.label}
                                   </MenuItem>
                        })}
                    </Menu>
                </Box>
            </Container>
        </Box>
    )
}

export default ViewRequestSupplierBar;
