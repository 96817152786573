import {
    BrowserRouter as Router,
    Navigate,
    Routes,
    Route
} from "react-router-dom";
import * as React from "react";
import AppTitle from "./components/AppTitle";
import ReactGA from 'react-ga4';
import Website from "./pages/website";
import AuthLayout from "./auth/AuthLayout";
import PrivateRoute from "./auth/private-route";

const TRACKING_ID = "G-92N9B1P3E5"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App(props) {
    return (
        <Router>
            <AppTitle />
            <Routes>
                <Route exact path="/auth" element={<Navigate to="/auth/login" replace/>} />
                <Route path="/auth/*" element={<AuthLayout />} />
                <Route exact path='/app/*' element={<PrivateRoute/>}/>
                <Route path="/*" element={<Website />} />
            </Routes>
        </Router>
    )
}

export default App;