import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {Tab, Tabs, Typography} from "@mui/material";
import React, {useState} from "react";

function SupplierHomeBar(props) {
    const [tab, setTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTab(newValue);

        if (props.onTabChange) {
            props.onTabChange(newValue);
        }
    };

    return (
        <Box
            sx = {{
                backgroundColor: "#FFFFFF",
                borderBottomColor: "#E7E7EF",
                borderBottomWidth: "1px",
                borderBottomStyle: "solid"
            }}
        >
            <Container
                sx = {{
                    paddingTop: {
                        md: 3,
                        xs: 2
                    },
                    display: "flex",
                    gap: 2,
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "start"
                }}
            >
                <Typography component="div" variant="h3">Cereri</Typography>
                <Box>
                    <Tabs value={tab} onChange={handleTabChange} aria-label="detalii cerere">
                        <Tab label="Cereri Preluate" />
                        <Tab label="Lista de Cereri" />
                        <Tab label="Finalizate" />
                    </Tabs>
                </Box>
            </Container>
        </Box>
    )
}

export default SupplierHomeBar;