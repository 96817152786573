import * as React from 'react';
import {Alert, Collapse, FormControl, FormControlLabel, FormLabel, InputAdornment, Link, Radio, RadioGroup, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import logo from "../images/logo_new.png";
import LoadingButton from "@mui/lab/LoadingButton";
import {AlertTitle} from "@mui/lab";
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

import { useFormik } from 'formik';
import * as yup from 'yup';
import {StylizedInput} from "../components/StylizedComponents";
import Auth2 from "./Auth2";

const validationSchema = yup.object({
    customerType: yup
        .string(),
    cui: yup
        .string()
        .when("customerType", {
           is: "JURIDICAL",
            then: yup.string().required("Completeaza CUI-ul")
        }),
    lastName: yup
        .string('Introduce numele')
        .required('Completeaza numele'),
    firstName: yup
        .string('Introduce prenumele')
        .required('Completeaza prenumele'),
    email: yup
        .string('Introdu email')
        .email('Emailul nu este valid')
        .required('Completeaza email'),
    phoneNumber: yup
        .string('Introdu numarul de telefon')
        .required('Completeaza numarul de telefon'),
    password: yup
        .string('Introdu parola')
        .min(8, 'Parola trebuie sa aiba mai mult de 8 caractere')
        .required('Completeaza parola'),
    password_confirmation: yup
        .string('Confirma parola')
        .required('Confirma parola')
        .oneOf([yup.ref('password'), null], 'Parolele nu se potrivesc')
});

function SignUp(props) {
    const formik = useFormik({
         initialValues: {
             customerType: "PHYSICAL"
         },
         validationSchema: validationSchema,
         onSubmit: (values) => {
             setLoading(true);
             let email = values.email;
             let password = values.password;

             return Auth2.register({
                ...values,
                 phoneNumber: '+40' + values.phoneNumber,
                 userType: "CUSTOMER",
                 cui: values.cui + ""
             }).then(user => {
                 showSuccess("Contul a fost creat cu success!");
                 setLoading(false);
                 navigate("/auth/confirm", { state: {email: values.email}});
             }).catch(err => {
                 setLoading(false);
                 showError("Contul nu a putut fi creat. Te rugam sa incerci mai tarziu.");
             });
         },
    });

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [infoOpen, setInfoOpen] = useState(false);
    const [infoType, setInfoType] = useState("error");
    const [infoMessage, setInfoMessage] = useState("");

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    function showError(message) {
        setInfoType("error");
        setInfoMessage(message);
        setInfoOpen(true);
    }

    function showSuccess(message) {
        setInfoType("success");
        setInfoMessage(message);
        setInfoOpen(true);
    }

    function hideInfo() {
        setInfoOpen(false);
    }

    function handleSubmit(e) {
        formik.handleSubmit(e);
        return false;
    }

    function goToSignIn() {
        navigate("/auth/login");
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx = {{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "400px"
            }}
        >
            <Box sx={{
                textAlign: "left",
                width: "100%",
                marginBottom: 4
            }}>
                <img src={logo} alt="TedConect logo" />
            </Box>
            <Typography
                variant="h2"
                sx = {{
                    marginBottom: 4
                }}
            >
                Creeaza cont
            </Typography>
            <Collapse in={infoOpen}>
                <Alert
                    variant="filled"
                    severity={infoType}
                    icon={infoType === "error" ? <DoDisturbAltIcon fontSize="inherit" /> : <CheckIcon fontSize="inherit" />}
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <AlertTitle>{infoType === "error" ? "Eroare" : "Success"}</AlertTitle>
                    {infoMessage}
                </Alert>
            </Collapse>

            <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 2,
                    width: "100%"
                }}
            >
                <FormLabel id="sign-up-form-user-type-label">
                    <Typography
                        variant="caption2">
                        Tip utilizator
                    </Typography>
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="sign-up-form-user-type-label"
                    name="customerType"
                    value={formik.values.customerType}
                    onChange={formik.handleChange}
                >
                    <FormControlLabel value="PHYSICAL" control={<Radio />} label="Persoana Fizica" />
                    <FormControlLabel value="JURIDICAL" control={<Radio />} label="Persoana Juridica" />
                </RadioGroup>
            </FormControl>

            {formik.values.customerType === "JURIDICAL" && <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 2,
                    width: "100%"
                }}
            >
                <FormLabel shrink htmlFor="sign-up-cui">
                    <Typography variant="caption2">
                        CUI
                    </Typography>
                </FormLabel>
                <StylizedInput
                    placeholder="Introdu CUI"
                    id="sign-up-cui"
                    name="cui"
                    value={formik.values.cui || ""}
                    onChange={formik.handleChange}
                    error={(formik.touched.cui || formik.submitCount > 0) && Boolean(formik.errors.cui)}
                    helperText={(formik.touched.cui || formik.submitCount > 0) && formik.errors.cui}
                />
            </FormControl>}

            <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 2,
                    width: "100%"
                }}
            >
                <FormLabel shrink htmlFor="sign-up-name">
                    <Typography variant="caption2">
                        Nume
                    </Typography>
                </FormLabel>
                <StylizedInput
                    placeholder="Introdu numele"
                    id="sign-up-name"
                    name="lastName"
                    value={formik.values.lastName || ""}
                    onChange={formik.handleChange}
                    error={(formik.touched.lastName || formik.submitCount > 0) && Boolean(formik.errors.lastName)}
                    helperText={(formik.touched.lastName || formik.submitCount > 0) && formik.errors.lastName}
                />
            </FormControl>

            <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 2,
                    width: "100%"
                }}
            >
                <FormLabel shrink htmlFor="sign-up-first-name">
                    <Typography variant="caption2">
                        Prenume
                    </Typography>
                </FormLabel>
                <StylizedInput
                    placeholder="Introdu prenumele"
                    id="sign-up-first-name"
                    name="firstName"
                    value={formik.values.firstName || ""}
                    onChange={formik.handleChange}
                    error={(formik.touched.firstName || formik.submitCount > 0) && Boolean(formik.errors.firstName)}
                    helperText={(formik.touched.firstName || formik.submitCount > 0) && formik.errors.firstName}
                />
            </FormControl>

            <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 2,
                    width: "100%"
                }}
            >
                <FormLabel shrink htmlFor="sign-up-email">
                    <Typography variant="caption2">
                        Email
                    </Typography>
                </FormLabel>
                <StylizedInput
                    placeholder="Introdu adresa de email"
                    id="sign-up-email"
                    name="email"
                    value={formik.values.email || ""}
                    onChange={formik.handleChange}
                    error={(formik.touched.email || formik.submitCount > 0) && Boolean(formik.errors.email)}
                    helperText={(formik.touched.email || formik.submitCount > 0) && formik.errors.email}
                />
            </FormControl>

            <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 2,
                    width: "100%"
                }}
            >
                <FormLabel shrink htmlFor="sign-up-phone-number">
                    <Typography variant="caption2">
                        Telefon
                    </Typography>
                </FormLabel>
                <StylizedInput
                    placeholder="Introdu numarul de telefon"
                    id="sign-up-phone-number"
                    name="phoneNumber"
                    value={formik.values.phoneNumber || ""}
                    onChange={formik.handleChange}
                    error={(formik.touched.phoneNumber || formik.submitCount > 0) && Boolean(formik.errors.phoneNumber)}
                    helperText={(formik.touched.phoneNumber || formik.submitCount > 0) && formik.errors.phoneNumber}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <div>+40</div>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>

            <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 4
                }}
            >
                <FormLabel shrink htmlFor="login-password">
                    <Typography variant="caption2">
                        Parola
                    </Typography>
                </FormLabel>
                <StylizedInput
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Introdu parola"
                    id="login-password"
                    name="password"
                    value={formik.values.password || ""}
                    onChange={formik.handleChange}
                    error={(formik.touched.password || formik.submitCount > 0) && Boolean(formik.errors.password)}
                    helperText={(formik.touched.password || formik.submitCount > 0) && formik.errors.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}

                />
            </FormControl>

            <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 4
                }}
            >
                <FormLabel shrink htmlFor="sign-up-confirm-password">
                    <Typography variant="caption2">
                        Confirma parola
                    </Typography>
                </FormLabel>
                <StylizedInput
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirma parola"
                    id="sign-up-confirm-password"
                    name="password_confirmation"
                    value={formik.values.password_confirmation || ""}
                    onChange={formik.handleChange}
                    error={(formik.touched.password_confirmation || formik.submitCount > 0) && Boolean(formik.errors.password_confirmation)}
                    helperText={(formik.touched.password_confirmation || formik.submitCount > 0) && formik.errors.password_confirmation}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownConfirmPassword}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </FormControl>

            <Box sx={{
                marginBottom: 3
            }}>
                <LoadingButton
                    loading={loading}

                    type="submit"
                    variant="contained"
                    sx= {{
                        width: "100%"
                    }}
                >
                    Creeaza cont
                </LoadingButton>
            </Box>
            <Box sx={{
                textAlign: "center"
            }}>
                Ai deja cont? <Link variant="subtitle2" onClick={goToSignIn}>Intra in cont</Link>
            </Box>
        </Box>
    )
}

export default SignUp;