import {styled} from "@mui/material/styles";
import {Avatar, Badge, IconButton} from "@mui/material";
import CrayonSmallUpload from "../../icons/crayon-small-upload";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import {useUser} from "../../auth/private-route";
import getImageMimeType from "../../utils/get-image-mime-type";
import API2 from "../../graphql/laravel/API2";
import {updateUserPicture} from "../../graphql/laravel/UpdateUserPicture";
import axios from "axios";

const SmallUploadButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: theme.palette.primary.main
    },
    "& .MuiSvgIcon-root":{
        color: theme.palette.primary.main
    },
    border: `2px solid ${theme.palette.shades.gray50}`,
}));
function AvatarUploader({}) {
    const user = useUser();
    const [avatar, setAvatar] = useState(user.picture);

    let fileInput = React.createRef();

    const onOpenFileDialog = () => {
        fileInput.current.click();
    };

    const onProcessFile = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        try {
            reader.readAsDataURL(file);
        } catch (err) {
            console.log(err);
        }
        reader.onloadend = (e) => {
            getImageMimeType(file, (mime) => {
                if (mime === "unknown") {
                    return;
                }

                let bodyFormData = new FormData();
                bodyFormData.set('operationName', 'UpdateUserPicture');
                bodyFormData.set('operations', JSON.stringify({'query': updateUserPicture, 'variables': {}}));
                bodyFormData.set('map', JSON.stringify({'content': ['variables.content']}));
                bodyFormData.append('content', file);

                API2.graphql("UpdateUserPicture",
                                    bodyFormData,
                                    null,
                                    true
                ).then(result => {
                    setAvatar(result.data.updateUserPicture.picture);
                }).catch(err => console.log(err));

            });
        };
    };

    return (
        <Box textAlign="center" marginBottom={3}>
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    <SmallUploadButton
                        aria-label="delete"
                        size="small"
                        variant="contained"
                        component="label">
                        <CrayonSmallUpload fontSize="inherit" />
                        <input
                            type="file"
                            onChange={onProcessFile}
                            ref={fileInput}
                            accept="image/*"
                            hidden
                        />
                    </SmallUploadButton>
                }
            >
                <Avatar key={avatar} sx={{ width: 100, height: 100 }} alt={user.firstName + " " + user.lastName} src={axios.defaults.baseURL.replace(/\/$/, "") + '/avatar/' + user.id + '?hash=' + Date.now()} />
            </Badge>
        </Box>
    );
}

export default AvatarUploader;