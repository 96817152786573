import {useState, useEffect} from "react";

function LocalIcon(props) {
    const [image, setImage] = useState("");

    useEffect(() => {
        import(`../images/${props.name}`).then(img => setImage(img.default));
    }, [])
    return (
        <img alt={props.alt ? props.alt : "No alt"} src={image} style={props.style} />
    )
}
export default LocalIcon;