import {SvgIcon} from "@mui/material";

function Facebook(props) {
    return (
        <SvgIcon
            {...props}
            width={props.width ? props.widht : "24"}
            height={props.height ? props.height : "25"}
            viewBox={props.viewport ? props.viewport : "0 0 24 25"}
        >
            <path
                d="M22 12.776C22 7.21935 17.5229 2.71484 12 2.71484C6.47715 2.71484 2 7.21935 2 12.776C2 17.7978 5.65687 21.9601 10.4375 22.7148V15.6842H7.89844V12.776H10.4375V10.5594C10.4375 8.0378 11.9304 6.64497 14.2146 6.64497C15.3088 6.64497 16.4531 6.84147 16.4531 6.84147V9.31745H15.1921C13.9499 9.31745 13.5625 10.093 13.5625 10.8886V12.776H16.3359L15.8926 15.6842H13.5625V22.7148C18.3432 21.9601 22 17.7978 22 12.776Z"
                fill="#87879A"/>
        </SvgIcon>
    )
}

export default Facebook;