import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {Typography} from "@mui/material";

function AccountBar(props) {
    let { id } = useParams();

    return (
        <Box
            sx = {{
                backgroundColor: "#FFFFFF",
                borderBottomColor: "#E7E7EF",
                borderBottomWidth: "1px",
                borderBottomStyle: "solid"
            }}
        >
            <Container
                sx = {{
                    height: {
                        xs: "88px",
                        md: "96px"
                    },
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundSize: {
                        xs: 'auto 64px',
                        md: '22%'
                    }
                }}
            >
                <Typography component="div" variant="h3">Contul meu</Typography>
            </Container>
        </Box>
    )
}

export default AccountBar;
