import Box from "@mui/material/Box";
import {Typography} from "@mui/material";

function LandingPageCard({number, text, description, icon}) {
    return (
        <Box sx ={{
            display: 'flex',
            flexDirection: 'row',
            gap: 3,
            border: "1px solid #E7E7EF",
            boxShadow: "0px 8px 20px rgba(26, 34, 56, 0.08)",
            borderRadius: "4px",
            padding: 3,
            height: '100%'
        }}>
            <Box color="shades.gray500">
                <Typography variant="h3">{number}</Typography>
            </Box>
            <Box sx = {{
                display: 'flex',
                flexDirection: 'column',
                gap: 1
            }}>
                <Box>
                    <Typography variant="h4">{text}</Typography>
                </Box>
                <Box>
                    <Typography variant="paragraph1">{description}</Typography>
                </Box>
            </Box>
            <Box sx = {{
                display: {
                    xs: 'none',
                    lg: 'block'
                }
            }}>
                <img src={icon} alt="Tedconect" />
            </Box>
        </Box>
    )
}

export default LandingPageCard;