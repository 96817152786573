import {useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import Box from "@mui/material/Box";
import logo from "../images/logo_new.png";
import {Alert, AlertTitle, Collapse, FormControl, InputAdornment, FormLabel, Typography} from "@mui/material";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CheckIcon from "@mui/icons-material/Check";
import * as React from "react";
import {StylizedInput} from "../components/StylizedComponents";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useNavigate, useSearchParams} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Auth2 from "./Auth2";

const validationSchema = yup.object({
    password: yup
        .string('Introduce-ti parola')
        .min(8, 'Parola trebuie sa aiba mai mult de 8 caractere')
        .required('Completeaza parola'),
    confirmPassword: yup
        .string('Confirma parola')
        .required('Confirma parola')
        .oneOf([yup.ref('password'), null], 'Parolele nu se potrivesc')
});

function PasswordResetConfirm(props) {
    const [ searchParams, setSearchParams ] = useSearchParams();
    const navigate = useNavigate();

    const [infoOpen, setInfoOpen] = useState(false);
    const [infoType, setInfoType] = useState("error");
    const [infoMessage, setInfoMessage] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
         initialValues: { },
         validationSchema: validationSchema,
         onSubmit: (values) => {
             setLoading(true);
             Auth2.resetPassword(searchParams.get('email'), values.password, values.password, searchParams.get('token')).then((result) => {
                 showSuccess("Parola a fost resetata cu success!");
                 setLoading(false);
                 navigate("/auth/login");
             })
             .catch(err => {
                 setLoading(false);
                 showError("A aparut o eroare. Te rugam sa incerci mai tarziu");
             });
         }
     });

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    function showError(message) {
        setInfoType("error");
        setInfoMessage(message);
        setInfoOpen(true);
    }

    function showSuccess(message) {
        setInfoType("success");
        setInfoMessage(message);
        setInfoOpen(true);
    }

    function handleSubmit(e) {
        formik.handleSubmit(e);
        return false;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx = {{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "400px"
            }}
        >
            <Box sx={{
                textAlign: "left",
                width: "100%",
                marginBottom: 4
            }}>
                <img src={logo} alt="TedConect logo" />
            </Box>
            <Typography
                variant="h2"
                sx = {{
                    marginBottom: 4
                }}
            >
                Reseteaza parola
            </Typography>
            <Collapse in={infoOpen}>
                <Alert
                    variant="filled"
                    severity={infoType}
                    icon={infoType === "error" ? <DoDisturbAltIcon fontSize="inherit" /> : <CheckIcon fontSize="inherit" />}
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <AlertTitle>{infoType === "error" ? "Eroare" : "Success"}</AlertTitle>
                    {infoMessage}
                </Alert>
            </Collapse>
            <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 4
                }}
            >
                <FormLabel shrink htmlFor="password-reset-confirm-password">
                    <Typography variant="caption2">
                        Parola noua
                    </Typography>
                </FormLabel>
                <StylizedInput
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Introduce-ti noua parola"
                    id="password-reset-confirm-password"
                    name="password"
                    value={formik.values.password || ""}
                    onChange={formik.handleChange}
                    error={(formik.touched.password || formik.submitCount > 0) && Boolean(formik.errors.password)}
                    helperText={(formik.touched.password || formik.submitCount > 0) && formik.errors.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}

                />
            </FormControl>

            <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 4
                }}
            >
                <FormLabel shrink htmlFor="password-reset-confirm-confirm-password">
                    <Typography variant="caption2">
                        Confirma parola
                    </Typography>
                </FormLabel>
                <StylizedInput
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirma parola"
                    id="password-reset-confirm-confirm-password"
                    name="confirmPassword"
                    value={formik.values.confirmPassword || ""}
                    onChange={formik.handleChange}
                    error={(formik.touched.confirmPassword || formik.submitCount > 0) && Boolean(formik.errors.confirmPassword)}
                    helperText={(formik.touched.confirmPassword || formik.submitCount > 0) && formik.errors.confirmPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownConfirmPassword}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </FormControl>
            <Box sx={{
                marginBottom: 3
            }}>
                <LoadingButton
                    loading={loading}

                    type="submit"
                    variant="contained"
                    sx= {{
                        width: "100%"
                    }}
                >
                    Reseteaza parola
                </LoadingButton>
            </Box>
        </Box>
    )
}

export default PasswordResetConfirm;