import {AutoWidthSvg} from "../../../components/StylizedComponents";

function LandingPageCardIcon(props) {
    return (
        <AutoWidthSvg {...props} viewBox="0 0 40 40">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.33337 6.66669C5.57195 6.66669 3.33337 8.90526 3.33337 11.6667V28.3334C3.33337 31.0948 5.57195 33.3334 8.33337 33.3334H31.6667C34.4281 33.3334 36.6667 31.0948 36.6667 28.3334V11.6667C36.6667 8.90526 34.4281 6.66669 31.6667 6.66669H8.33337ZM31.6667 10H8.33337C7.4129 10 6.66671 10.7462 6.66671 11.6667L6.66671 21.6667L6.66671 23.3334V26.6667V28.3334C6.66671 29.2538 7.4129 30 8.33337 30H31.6667C32.5872 30 33.3334 29.2538 33.3334 28.3334V26.6667V23.3334L33.3334 21.6667L33.3334 11.6667C33.3334 10.7462 32.5872 10 31.6667 10Z" />
            <path d="M10 15C10 14.0795 10.7462 13.3333 11.6667 13.3333H15C15.9205 13.3333 16.6667 14.0795 16.6667 15V18.3333C16.6667 19.2538 15.9205 20 15 20H11.6667C10.7462 20 10 19.2538 10 18.3333V15Z" />
            <path d="M10 25C10 24.0795 10.7462 23.3333 11.6667 23.3333H28.3333C29.2538 23.3333 30 24.0795 30 25C30 25.9205 29.2538 26.6666 28.3333 26.6666H11.6667C10.7462 26.6666 10 25.9205 10 25Z" />
        </AutoWidthSvg>
    )
}

export default LandingPageCardIcon;