import {createTheme, responsiveFontSizes} from "@mui/material/styles";
let palette = {
    type: 'light',
    primary: {
        main: '#FF5805',
        light: '#FFEEE6',
        dark: '#C24100'
    },
    text: {
        primary: '#0A0A0F',
        black: '#0A0A0F'
    },
    neutralButton: {
        main: '#FFFFFF',
        dark: '#EDEDF3'
    },
    shades: {
        gray500: '#87879A',
        gray100: '#EDEDF3',
        gray50: '#F6F6F9',
        border: '#E7E7EF'
    },
    secondary: {
        main: '#f50057',
    },
    success: {
        light: '#F2FDEC',
            dark: '#239A3C',
            main: '#46D64B'
    },
    warning: {
        light: '#FFFBEB',
            dark: '#B77800',
            main: '#FFB600'
    },
    error: {
        light: '#FFECEB',
            main: '#FC372D',
            dark: '#B51629'
    },
    background: {
        default: '#F6F6F9',
    },
};
let theme = createTheme();
theme = createTheme({
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: "0.75rem",
                    fontWeight: 600,
                    borderRadius: "4px",
                    textTransform: "inherit"
                }
            }
        },
        MuiTab: {
          styleOverrides: {
              root: {
                  "&.Mui-selected": {
                      fontWeight: 600,
                      color: "#0A0A0F"
                  },
                  textTransform: 'inherit'
              }
          }
        },
        MuiFormHelperText: {
          styleOverrides: {
              root: {
                  fontSize: "0.75rem",
                  fontWeight: 400
              }
          }
        },
        MuiFormControlLabel: {
          styleOverrides: {
              label: {
                  fontSize: "1rem",
                  fontWeight: 400,
                  fontStyle: "normal"
              }
          }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-required": {
                        color: "#FC372D",
                        fontWeight: 600
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    "&::placeholder": {
                        fontSize: "0.875rem",
                        fontWeight: 400
                    }
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                filledError: {
                    backgroundColor: '#FFECEB',
                    "& .MuiAlert-icon": {
                        color: '#FC372D'
                    }
                },
                filledSuccess: {
                    backgroundColor: '#F2FDEC',
                    "& .MuiAlert-icon": {
                        color: '#46D64B'
                    }
                },
                standardInfo: {
                    backgroundColor: '#EBF2FF',
                    "& .MuiAlert-icon": {
                        color: '#3469F9'
                    }
                },
                message: {
                    color: '#0A0A0F',
                    fontSize: "0.875rem",
                    fontWeight: 400
                },
                icon: {
                    color: '#FC372D'
                }
            }
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    color: "#0A0A0F",
                    fontWeight: 600
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                labelContainer: {
                    "& .Mui-disabled": {
                        color: "#87879A",
                        fontWeight: 400,
                        fontSize: "0.875rem",
                        [theme.breakpoints.down('md')]: {
                            display: "none"
                        }
                    },
                    "& .Mui-completed": {
                        [theme.breakpoints.down('md')]: {
                            display: "none"
                        }
                    }

                },
                iconContainer: {
                    "&.Mui-disabled svg": {
                        color: "#EDEDF3"
                    },
                    "&.Mui-disabled text": {
                        fill: "#87879A",
                        fontWeight: 600
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        fontWeight: 600
                    },
                    ":hover": {
                        backgroundColor: palette.primary.light
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: "none",
                    cursor: "pointer",
                    textAlign: "left"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                icon: {
                    color: 'inherit'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.down('md')]: {
                        padding: theme.spacing(2)
                    },
                    [theme.breakpoints.up('md')]: {
                        padding: theme.spacing(3)
                    }
                }
            }
        },

        MuiCardActions: {
            styleOverrides: {
                root: {
                    "& > :not(:first-of-type)": {
                        marginLeft: 0
                    }
                }
            }
        },

        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: "1.25rem",
                    color: theme.palette.text.black
                }
            }
        }
    },
    typography: {
        allVariants: {
            fontFamily: "GeneralSans-Variable"
        },
        h1: {
            fontSize: "2.5rem",
            fontWeight: 700
        },
        h2: {
            fontSize: "2rem",
            [theme.breakpoints.down('md')]: {
                fontWeight: 600
            },
            [theme.breakpoints.between("md", "xl")]: {
                fontWeight: 700
            },
            [theme.breakpoints.up("xl")]: {
                fontWeight: 700
            }
        },
        h3: {
            fontSize: "1.75rem",
            fontWeight: 600
        },
        h4: {
            fontSize: "1.5rem",
            fontWeight: 600
        },
        h5: {
            fontSize: "1.25rem",
            fontWeight: 600
        },
        h6: {
          fontSize: "1.125rem",
          fontWeight: 600
        },
        caption: {
            fontSize: "0.875rem",
            fontWeight: 600,
            fontStyle: "normal",
            color: "#0A0A0F"
        },
        caption1: {
            fontWeight: 400,
            fontSize: "0.875rem"
        },
        footerLink: {
            color: "#87879A",
            fontSize: "0.875rem",
            fontWeight: 600
        },
        caption2: {
            fontSize: "0.875rem",
            fontWeight: 600,
            fontStyle: "normal",
            color: "#0A0A0F"
        },
        subtitle2: {
            fontSize: "1rem",
            fontWeight: 600
        },
        display2: {
            fontSize: "3rem",
            fontWeight: 700,
            lineHeight: 1.5,
            fontStyle: "normal"
        },
        paragraph1: {
            fontSize: "1rem",
            fontWeight: 400
        },
        paragraph2: {
            fontSize: "1rem",
            fontWeight: 600
        },
        bigText: {
            fontSize: "2rem",
            fontWeight: 600,
            lineHeight: 1,
        },
        label2: {
            fontSize: "0.75rem",
            fontWeight: 600,
            color: "#87879A"
        },
        title1: {
            fontSize: "1.5rem",
            fontWeight: 600
        }
    },
    palette: palette,
    shape: {
        borderRadius: 5,
    }
});

const responsivenessOptions = {
    variants: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "display2",
        "bigText"
    ]
}

theme = responsiveFontSizes(theme, responsivenessOptions);
export default theme;