import Box from "@mui/material/Box";
import logo from "../images/logo_new.png";
import {Alert, Collapse, FormControl, FormLabel, Typography} from "@mui/material";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CheckIcon from "@mui/icons-material/Check";
import {AlertTitle} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import {StylizedInput} from "../components/StylizedComponents";
import {useNavigate} from "react-router-dom";
import Auth2 from "./Auth2";

const validationSchema = yup.object({
    email: yup
        .string('Introduce email')
        .email('Emailul nu este valid')
        .required('Completeaza email')
});

function PasswordReset(props) {
    const navigate = useNavigate();
    const [infoOpen, setInfoOpen] = useState(false);
    const [infoType, setInfoType] = useState("error");
    const [infoMessage, setInfoMessage] = useState("");

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
         initialValues: { },
         validationSchema: validationSchema,
         onSubmit: (values) => {
             setLoading(true);
             Auth2.forgotPassword(values.email).then((result) => {
                 console.log('result', result);
                 showSuccess('V-am trimis prin email link-ul pentru resetarea parolei.');
                 setLoading(false);
             })
             .catch(err => {
                 setLoading(false);
                 showError("A aparut o eroare. Te rugam sa introduci o adresa de email asociata unui cont.")
             });
         }
     });

    function showError(message) {
        setInfoType("error");
        setInfoMessage(message);
        setInfoOpen(true);
    }

    function showSuccess(message) {
        setInfoType("success");
        setInfoMessage(message);
        setInfoOpen(true);
    }

    function handleSubmit(e) {
        formik.handleSubmit(e);
        return false;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx = {{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "400px"
            }}
        >
            <Box sx={{
                textAlign: "left",
                width: "100%",
                marginBottom: 4
            }}>
                <img src={logo} alt="TedConect logo" />
            </Box>
            <Typography
                variant="h2"
                sx = {{
                    marginBottom: 4
                }}
            >
                Reseteaza parola
            </Typography>
            <Collapse in={infoOpen}>
                <Alert
                    variant="filled"
                    severity={infoType}
                    icon={infoType === "error" ? <DoDisturbAltIcon fontSize="inherit" /> : <CheckIcon fontSize="inherit" />}
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <AlertTitle>{infoType === "error" ? "Eroare" : "Success"}</AlertTitle>
                    {infoMessage}
                </Alert>
            </Collapse>

            <Typography
                sx = {{
                    marginBottom: 4
                }}
            >
                Completează adresa de e-mail pentru a reseta parola.
            </Typography>

            <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 4,
                    width: "100%"
                }}
            >
                <FormLabel shrink htmlFor="password-reset-email">
                    <Typography variant="caption2">
                        Email
                    </Typography>
                </FormLabel>
                <StylizedInput
                    placeholder="Introduce-ti adresa de email"
                    id="password-reset-email"
                    name="email"
                    value={formik.values.email || ""}
                    onChange={formik.handleChange}
                    error={(formik.touched.email || formik.submitCount > 0) && Boolean(formik.errors.email)}
                    helperText={(formik.touched.email || formik.submitCount > 0) && formik.errors.email}
                />
            </FormControl>
            <Box sx={{
                marginBottom: 3
            }}>
                <LoadingButton
                    loading={loading}

                    type="submit"
                    variant="contained"
                    sx= {{
                        width: "100%"
                    }}
                >
                    Trimite cod
                </LoadingButton>
            </Box>

        </Box>
    )
}
export default PasswordReset;