import {SvgIcon} from "@mui/material";

function Tiktok(props) {
    return (
        <SvgIcon
            {...props}
            width={props.width ? props.widht : "24"}
            height={props.height ? props.height : "25"}
            viewBox={props.viewport ? props.viewport : "0 0 24 25"}
        >
            <path
                d="M275.592 138.478C275.592 207.965 224.08 265.411 157.167 274.736C150.908 275.607 144.507 276.059 138.011 276.059C130.508 276.059 123.148 275.457 115.97 274.3C50.4677 263.755 0.429626 206.951 0.429626 138.47C0.429626 62.4857 62.0266 0.888763 138.011 0.888763C213.995 0.888763 275.592 62.4857 275.592 138.47V138.478Z"
                fill="#87879A"/>
            <path
                d="M205.336 99.9827V126.269C200.733 125.826 194.775 124.78 188.16 122.356C179.532 119.195 173.107 114.869 168.908 111.518V164.661L168.805 164.495C168.877 165.549 168.908 166.618 168.908 167.704C168.908 194.101 147.438 215.579 121.033 215.579C94.6274 215.579 73.1577 194.093 73.1577 167.704C73.1577 141.314 94.6274 119.82 121.033 119.82C123.616 119.82 126.151 120.026 128.63 120.423V146.337C126.246 145.481 123.695 145.022 121.033 145.022C108.531 145.022 98.351 155.194 98.351 167.704C98.351 180.213 108.531 190.386 121.033 190.386C133.535 190.386 143.715 180.205 143.715 167.704C143.715 167.236 143.707 166.769 143.675 166.301V63.0245H169.954C170.049 65.2507 170.144 67.4927 170.231 69.7189C170.406 74.1 171.966 78.3069 174.684 81.7531C177.876 85.8015 182.582 90.5074 189.206 94.2627C195.401 97.7723 201.216 99.2776 205.336 99.9906V99.9827Z"
                fill="white"/>
        </SvgIcon>
    )
}

export default Tiktok;