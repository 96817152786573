import Box from "@mui/material/Box";
import {Typography} from "@mui/material";

function LandingPageSmallBox({text, icon}) {
    return (
        <Box sx = {{
            display: 'flex',
            flexDirection: "column",
            border: "1px solid #E7E7EF",
            borderRadius: "4px",
            alignItems: "center",
            padding: 4,
            minHeight: "200px"
        }}>
                <Box sx = {{ color: 'primary.main'}} >
                    {icon}
                </Box>
                <Box textAlign="center">
                    <Typography variant="h6">
                        {text}
                    </Typography>
                </Box>
        </Box>
    )
}

export default LandingPageSmallBox;