import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function AppTitle(props) {
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;

        if (path.indexOf("/app/requests/add") != -1) {
            document.title = "TedConect - Adaugare Cerere";
            return;
        }

        if (path.indexOf("/app/home") != -1) {
            document.title = "TedConect - Acasa";
            return;
        }

        if (path === "/app/requests") {
            document.title = "TedConect - Cereri";
            return;
        }

        if (path.indexOf("/app/requests/") != -1 && path.indexOf("/edit") != -1) {
            document.title = "TedConect - Editare Cerere";
            return;
        }

        if (path.indexOf("/app/requests/") != -1) {
            document.title = "TedConect - Vizualizare Cerere";
            return;
        }

    }, [location.pathname])

    return (<></>);
}

export default AppTitle;