import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";

function ViewRequestBar({request}) {
    let { id } = useParams();
    const navigate = useNavigate();
    const goToAddRequest = () => {
        console.log('request', request);
        if (request && request.utilityType) {
            navigate('/app/requests/' + id + '/' + request.utilityType.toLocaleLowerCase() + '/edit');
        }

    }
    return (
        <Box
            sx = {{
                backgroundColor: "#FFFFFF",
                borderBottomColor: "#E7E7EF",
                borderBottomWidth: "1px",
                borderBottomStyle: "solid"
            }}
        >
            <Container
                sx = {{
                    height: {
                        xs: "88px",
                        md: "96px"
                    },
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundSize: {
                        xs: 'auto 64px',
                        md: '22%'
                    }
                }}
            >
                <Typography component="div" variant="h3">Detalii Cerere</Typography>
                <Box>
                    <Button variant="contained" onClick={goToAddRequest}>Modifica Cerere</Button>
                </Box>
            </Container>
        </Box>
    )
}

export default ViewRequestBar;
