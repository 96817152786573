import Container from "@mui/material/Container";
import {Fab, Grid, Typography} from "@mui/material";
import { Link } from "react-router-dom";
import LocalIcon from "./LocalIcon";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import './footer.css';
import FacebookIcon from "../icons/facebook-icon";
import InstagramIcon from "../icons/instagram-icon";
import LinkedinIcon from "../icons/linkedin-icon";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TiktokIcon from "../icons/tiktok-icon";

const fabStyle = {
    color: 'white',
    bgcolor: '#25D366',
    position: 'fixed',
    bottom: 16,
    right: 16,
    '&:hover': {
        bgcolor: '#128C7E'
    }
};

function Footer(props) {
    return (
        <Container className="footer" maxWidth="xl" sx = {{
            paddingBottom: 3,
            paddingTop: 3
        }}>
            <Grid container spacing={2} alignItems="center">
               <Grid item>
                   <LocalIcon name="logo_footer.svg" alt="Logo" />
               </Grid>
                <Grid item>
                    <Typography variant="caption1">
                        TedConect © 2023
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{
                marginTop: 2,
                marginBottom: 2
            }} />
            <Box
                sx = {{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: {
                        xs: "column",
                        md: "row"
                    }
                }}
            >
                <Box
                    sx = {{
                        display: "flex",
                        flexDirection: {
                            xs: "column",
                            md: "row"
                        },
                        width: "100%",
                        justifyContent: "left",
                        gap: 2
                    }}
                >
                    <Link to="/termeni-si-conditii">
                        <Typography variant="footerLink">
                            Conditii de utilizare
                        </Typography>
                    </Link>
                    <Link to="/politica-de-confidentialitate">
                        <Typography variant="footerLink">
                            Politica de confidentialitate
                        </Typography>
                    </Link>
                    <Link to="/contact">
                        <Typography variant="footerLink">
                            Contact
                        </Typography>
                    </Link>
                </Box>


                <Box
                    sx={{
                        textAlign: "left",
                        display: "flex",
                        gap: 3
                    }}
                >
                    <a href="https://www.facebook.com/profile.php?id=61557679783137" target="_blank">
                        <FacebookIcon/>
                    </a>
                    <a href="https://www.instagram.com/ted_conect/" target="_blank">
                        <InstagramIcon/>
                    </a>
                    <a href="https://www.linkedin.com/company/93616584" target="_blank">
                        <LinkedinIcon/>
                    </a>
                    <a href="https://www.linkedin.com/company/93616584" target="_blank">
                        <TiktokIcon/>
                    </a>
                </Box>
            </Box>
            <a href={"https://wa.me/40742562165?text=Buna%20ziua%21%20As%20dori%20sa%20raportez%20o%20problema."} target="_blank">
            <Fab
                sx={fabStyle}
                size="medium"
            >
                <WhatsAppIcon />
            </Fab>
            </a>
        </Container>
    )
}

export default Footer;