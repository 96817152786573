import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

function RequestsBar(props) {
    const navigate = useNavigate();
    const goToAddRequest = () => {
        navigate('/app/requests/add');
    }
    return (
        <Box
            sx = {{
                backgroundColor: "#FFFFFF",
                borderBottomColor: "#E7E7EF",
                borderBottomWidth: "1px",
                borderBottomStyle: "solid"
            }}
        >
            <Container
                sx = {{
                    height: {
                        xs: "88px",
                        md: "96px"
                    },
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundSize: {
                        xs: 'auto 64px',
                        md: '22%'
                    }
                }}
            >
                <Typography component="div" variant="h3">Lista de Cereri</Typography>
                <Box>
                    <Button variant="contained" onClick={goToAddRequest}>Creeaza Cerere</Button>
                </Box>
            </Container>
        </Box>
    )
}

export default RequestsBar;