import {AutoWidthSvg} from "../../../components/StylizedComponents";

function LandingPageShieldIcon(props) {
    return (
        <AutoWidthSvg {...props} viewBox="0 0 40 40">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.4729 6.18937L9.47295 9.5227C8.79238 9.74956 8.33333 10.3865 8.33333 11.1038V20C8.33333 23.3762 10.0244 26.259 12.4739 28.6828C14.9286 31.1119 17.8838 32.8067 19.7114 33.711C19.9008 33.8047 20.0992 33.8047 20.2886 33.711C22.1162 32.8067 25.0714 31.1119 27.5261 28.6828C29.9756 26.259 31.6667 23.3762 31.6667 20V11.1038C31.6667 10.3865 31.2076 9.74956 30.5271 9.5227L20.527 6.18937C20.1849 6.07533 19.8151 6.07533 19.4729 6.18937ZM8.41886 6.36042C6.37715 7.04099 5 8.95169 5 11.1038V20C5 29.1701 14.267 34.7363 18.2332 36.6986C19.3542 37.2533 20.6458 37.2533 21.7668 36.6986C25.733 34.7363 35 29.1701 35 20V11.1038C35 8.95169 33.6229 7.041 31.5811 6.36043L21.5811 3.02709C20.5548 2.68498 19.4452 2.68498 18.4189 3.02709L8.41886 6.36042Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.1786 15.4882C26.8294 16.139 26.8294 17.1943 26.1786 17.8452L20.4547 23.569C19.2831 24.7406 17.3836 24.7406 16.2121 23.569L13.8215 21.1785C13.1707 20.5276 13.1707 19.4724 13.8215 18.8215C14.4724 18.1706 15.5277 18.1706 16.1786 18.8215L18.3334 20.9763L23.8215 15.4882C24.4724 14.8373 25.5277 14.8373 26.1786 15.4882Z" />
        </AutoWidthSvg>
    )
}

export default LandingPageShieldIcon;