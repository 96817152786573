import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {useState} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {styled} from "@mui/material/styles";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    boxShadow: 'none',
    marginBottom: theme.spacing(2)
}));

function LandingPageQuestion({question, answer}) {
    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
        if (expanded) {
            setExpanded(false);
        } else {
            setExpanded(true);
        }
    };

    return (
        <CustomAccordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography variant="h4">
                    {question}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="paragraph1">
                    {answer}
                </Typography>
            </AccordionDetails>
        </CustomAccordion>
    )
}

export default LandingPageQuestion;