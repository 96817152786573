import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import LandingPageGridBackground from "../../images/landing-page-grid-background.svg";
import LandingPageIllustration from '../../images/landing-page-illustration.svg';
import LandingPageEdit from '../../images/landing-page-edit.png';
import LandingPageSearch from '../../images/landing-page-search.png';
import LandingPagePresentation from '../../images/landing-page-presentation.png';
import LandingPageDone from '../../images/landing-page-done.png';
import LandingPageLaptop from '../../images/landing-page-laptop.png';
import WelcomeImg from "../../images/house.svg";
import Grid from "@mui/material/Grid";
import LandingPageSmallBox from "./components/landing-page-small-box";
import LandingPageTargetIcon from "./icons/landing-page-target-icon";
import Flex from "../../components/Flex";
import LandingPageShieldIcon from "./icons/landing-page-shield-icon";
import LandingPageCardIcon from "./icons/landing-page-card-icon";
import LandingPageDialogIcon from "./icons/landing-page-dialog-icon";
import LandingPageCard from "./components/landing-page-card";
import LandingPageQuestion from "./components/landing-page-question";
import Divider from "@mui/material/Divider";
import Footer from "../../components/footer";
import {Link} from "react-router-dom";

function Main() {
    return (
        <Box>
            <Container>
                <Box
                    sx = {{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: "center",
                        paddingTop: 3
                    }}
                >
                    <Box textAlign="center">
                        <Typography variant="display2">Lasa grija racordării</Typography><br />
                        <Typography variant="display2">in seama noastră</Typography>
                    </Box>
                    <Box maxWidth="550px" textAlign="center" marginBottom={4}>
                        <Typography variant="paragraph1">
                            Obiectivul nostru e să schimbăm modul în care obții utilitățile tale și modul în care interacționezi cu furnizorii tăi de utilități
                        </Typography>
                    </Box>
                    <Box>
                    <Link to="/auth/sign-up" className="silent">
                        <Button variant="contained">
                             Încearcă Gratuit
                        </Button>
                        </Link>
                    </Box>
                </Box>
            </Container>
            <Box sx = {{
                background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #EAEAF1 100%);"
            }}>
                <Container sx = {{
                    backgroundImage:`url(${LandingPageGridBackground})`,
                    backgroundRepeat: 'no-repeat',
                    textAlign: "center",
                    paddingTop: 5
                }}>
                    <img src={LandingPageIllustration} width="100%" alt="TedConect landing page illustration" />
                </Container>
            </Box>
            <br />
            <br />
            <Container>
                <Grid container alignItems="center" spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="paragraph2" color="primary.main">
                            SOLUTIA NOASTRA
                        </Typography>
                        <br />
                        <Typography variant="bigText">
                            Credem în transparență, opțiuni de plată convenabile și o experiență digitală totală pentru fiecare pas din proces
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Flex gap={3}>
                                    <LandingPageSmallBox
                                        text="Transparenta totala pe tot parcursul procesului"
                                        icon={<LandingPageTargetIcon height="40" width="40"/>}
                                    />
                                    <LandingPageSmallBox
                                        text="Securitate si control asupra datelor personale"
                                        icon={<LandingPageShieldIcon height="40" width="40"/>}
                                    />
                                </Flex>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx = {{
                                    display: 'flex',
                                    gap: 3,
                                    flexDirection: 'column',
                                    paddingTop: {
                                        xs: 0,
                                        md: 7
                                    }
                                }}>
                                    <LandingPageSmallBox
                                        text="Plata online pentru serviciile contractate"
                                        icon={<LandingPageCardIcon height="40" width="40"/>}
                                    />
                                    <LandingPageSmallBox
                                        text="Comunicare exclusiv digitală pe toata durata colaborării"
                                        icon={<LandingPageDialogIcon height="40" width="40"/>}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Box textAlign="center" marginBottom={1}>
                    <Typography variant="paragraph2" color="primary.main">
                       CUM FUNCTIONEAZA?
                    </Typography>
                </Box>
                <Box textAlign="center" sx = {{
                    marginBottom: {
                        xs: 4,
                        md: 10
                    }
                }}>
                    <Typography variant="bigText">
                        Simplificăm modul de branșare la diferiți distribuitori
                        de Gaze, Apă si Electricitate din zona ta
                    </Typography>
                    <br />
                </Box>

                <Grid container spacing={5} marginBottom={5}>
                    <Grid item xs={12} md={6}>
                        <LandingPageCard
                            number="01"
                            text="Crează o cerere de branșament"
                            description="Completarea cererilor nu a fost niciodată atât de ușoară! Cu formularul nostru ghidat simplu și intuitiv, puteți completa cererea în câțiva pași simpli și fără stres."
                            icon={LandingPageEdit}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LandingPageCard
                            number="02"
                            text="Analizează ofertele primite si accept-o pe cea mai favorabilă"
                            description="Revizuiți ofertele noastre de conectare la utilități pe mobil pentru a găsi soluția perfectă pentru dumneavoastră. Veți găsi costuri transparente, timp de implementare și detalii despre furnizori."
                            icon={LandingPageSearch}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <LandingPageCard
                            number="03"
                            text="Furnizorul va incepe lucrul la cererea ta"
                            description="Soluția noastră propune un proces simplu și curat pentru conectarea la utilități. Simplificăm procesul pentru clienții si furnizorii noștri, economisind timp și bani, iar rezultatul final este un serviciu de calitate și o experiență plăcută pentru toți cei implicați."
                            icon={LandingPagePresentation}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LandingPageCard
                            number="04"
                            text="Odată finalizat, vei primi documentele necesare"
                            description="La finalizarea contractului, ne asigurăm că toate obiectivele dumneavoastră sunt îndeplinite și că sunteți mulțumit de serviciile furnizate. Veți primi prin intermediul aplicației toate documentele justificative pentru lucrarea executată, precum și factura aferentă."
                            icon={LandingPageDone}
                        />
                    </Grid>
                </Grid>
            </Container>
            <Box sx = {{
                background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FF5805 100%);",
                paddingTop: {
                    xs: 5,
                    md: 11
                }
            }}>
                <Box sx = {{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Box textAlign="center">
                        <Typography variant="paragraph2" color="primary.main">
                            PENTRU FURNIZORI
                        </Typography>
                    </Box>
                    <Box textAlign="center" marginBottom={2}>
                        <Typography variant="bigText">
                            Accesează o piață mare
                            de clienți devenind furnizor
                        </Typography>
                    </Box>
                    <Box textAlign="center" marginBottom={5}>
                        <Typography variant="paragraph1">
                         Scapati de grija costurilor de marketing. Noi aducem clientii, si dumneavoastra furnizati serviciile.
                        </Typography>
                    </Box>
                    <Box textAlign="center" sx = {{
                        marginBottom: {
                            xs: 5,
                            md: 9
                        }
                    }}>
                        <Link to="/contact" className="silent">
                        <Button variant="contained">
                            Colaboreaza cu noi
                        </Button>
                        </Link>
                    </Box>
                    <Box>
                        <img style={{"margin-bottom": "-77px", "width": "100%"}} src={LandingPageLaptop} alt="Colaboreaza cu TedConect" />
                    </Box>
                </Box>
            </Box>
            <Container sx = {{ marginBottom: '100px' }}>
                <Box sx = {{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: 17
                }}>
                    <Box marginBottom={1}>
                        <Typography variant="paragraph2" color="primary.main">
                            INTREBARI FRECVENTE
                        </Typography>
                    </Box>
                    <Box textAlign="center" marginBottom={5}>
                        <Typography variant="bigText">
                            Poate te intrebai...
                        </Typography>
                    </Box>
                    <Box sx = {{
                        border: "1px solid #E7E7EF",
                        borderRadius: "4px",
                        padding: 5,
                        marginBottom: 20,
                        width: {
                            xs: '100%',
                            md: '75%'
                        }
                    }}>
                        <LandingPageQuestion
                            question="Ce costuri implică utilizarea aplicației?"
                            answer="Aplicația nu are niciun cost ascuns. Înscrierea este gratuită, cererile sunt gratuite și toate costurile asociate cu cererea dumneavoastră sunt afișate înainte de a accepta colaborarea cu orice furnizor."
                        />
                        <Divider />
                        <LandingPageQuestion
                            question="Dacă am o problemă sau o întrebare referitoare la cerea mea, ce ar trebui să fac?"
                            answer="Puteți utiliza aplicația pentru a comunica exclusiv digital cu orice furnizor. Conversația va fi disponibilă pentru dumneavoastră ulterior, facilitând colaborarea și oferind transparență pe întreg parcursul rezolvării cererii dumneavoastră."
                        />
                        <Divider />
                        <LandingPageQuestion
                            question="Dacă întâmpin o problemă cu aplicația sau am o situație care necesită lămuriri, cum vă pot contacta?"
                            answer="Pentru orice problemă sau întrebare, vă rugăm să ne contactați la adresa de e-mail contact@tedconect.ro"
                        />
                    </Box>
                </Box>

                <Box sx = {{
                    background: "linear-gradient(92.61deg, #FF8040 0%, #FF5805 100%)",
                }}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Box padding={5} sx = {{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: {
                                    xs: 'center',
                                    md: 'start'
                                }
                            }}>
                                <Typography variant="bigText" color="#FFFFFF" sx = {{
                                    textAlign: {
                                        xs: 'center',
                                        md: 'left'
                                    }
                                }}>
                                    Procesul de racordare nu trebuie sa fie o bataie de cap
                                </Typography>
                                <br />
                                <Link to="/auth/sign-up" className="silent">
                                 <Button variant="contained" color="neutralButton">
                                    Incearca gratuit
                                  </Button>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx = {{
                                display: 'flex',
                                height: '100%',
                                alignItems: {
                                    md: 'end',
                                    xs: 'end'
                                },
                                paddingRight: {
                                    md: 6
                                },
                                paddingTop: {
                                    md: 6
                                },
                                justifyContent: {
                                    xs: 'center',
                                    md: 'end'
                                }
                            }}>
                                <img src={WelcomeImg} height="100%" alt="TedConect - racordare fara batai de cap" />
                            </Box>
                        </Grid>
                    </Grid>

                </Box>
            </Container>
            <Box sx = {{
                backgroundColor: 'shades.gray50'
            }}>
                <Footer />
            </Box>

        </Box>
    )
}

export default Main;