import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import './fonts/GeneralSans/css/general-sans.css';

import App from './App.js';

import theme from "./theme";
import Loading from "./components/loading";

import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
//axios.defaults.baseURL = 'http://localhost:8000';
axios.defaults.baseURL = 'https://api.tedconect.ro/';
axios.defaults.maxRedirects = 0;

//Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<Loading />}>
            <App />
        </Suspense>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
