import {styled} from "@mui/material/styles";
import {Paper, TextField, Box, Select, SvgIcon} from "@mui/material";

const StylizedPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
}));

const StylizedInput = styled(TextField)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    'label + &': {
        marginTop: theme.spacing(0.75),
    },
    '& fieldset': {
        borderColor: "#E7E7EF"
    }
}));

const StylizedSelect = styled(Select)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(0.75),
    },
}));

const FormStep = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px"
}));

const AutoWidthSvg = styled(SvgIcon)(({ theme }) => ({
    width: 'auto',
    height: 'auto'
}));

export {StylizedPaper, StylizedInput, FormStep, StylizedSelect, AutoWidthSvg};