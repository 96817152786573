//create a class to handle authentication
import axios from "axios";

export default class API2 {
    static async graphql(queryName, query, variables, isFileUpload) {
        try {
            return await API2.doCall(queryName, query, variables, isFileUpload);
        } catch (error) {
            if (error.response && error.response.status === 419) {
                console.log('csrf token is missing');
                //get the csrf token
                const csrfCookie = await axios.get('/sanctum/csrf-cookie');
            }
            throw error;
        }

        return await API2.doCall(queryName, query, variables, isFileUpload);
    }

    static async doCall(queryName, query, variables, isFileUpload) {
        try {
            const graphqlQuery = {
                "operationName": queryName,
                query,
                variables
            };
            const response = await axios.post('/graphql', isFileUpload ? query : graphqlQuery, {
                headers: {
                    'Content-Type': isFileUpload ? 'multipart/form-data':'application/json',
                    'Accept': 'application/json',
                }
            });

            if (response.data.errors && response.data.errors.length > 0) {
                throw response.data.errors[0];
            }

            return response.data;
        } catch (error) {
            throw error;
        }
    }
}