import Box from "@mui/material/Box";
import {Route, Routes} from "react-router-dom";
import * as React from "react";
import SignIn from "./sign-in";
import SignUp from "./sign-up";
import auth_image from '../images/login-illustration.svg';
import Confirm from "./confirm";
import PasswordReset from "./password-reset";
import PasswordResetConfirm from "./password-reset-confirm";
import {Typography} from "@mui/material";

function AuthLayout(props) {
    return (
        <Box
            display="grid" gap={2}
            sx = {{
                height: "100%",
                gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    md: "repeat(12, 1fr)"
                },
            }}
        >
            <Box
                gridColumn="1"
                gridRow="1"
                sx = {{
                    paddingTop: {
                        xs: 4,
                        md: "60px"
                    },
                    gridColumn: {
                        md: "span 6",
                        lg: "span 5"
                    },
                    paddingBottom: 5,
                    paddingX: {
                        xs: 3
                    },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Routes>
                    <Route exact path="/login" element={<SignIn />} />
                    <Route exact path="/sign-up" element={<SignUp />} />
                    <Route exact path="/confirm" element={<Confirm />} />
                    <Route exact path="/password-reset" element={<PasswordReset />} />
                    <Route exact path="/password-reset-confirm" element={<PasswordResetConfirm />} />
                </Routes>
            </Box>
            <Box
                gridColumn="2"
                gridRow="1"
                sx = {{
                    gridColumn: {
                        md: "span 6",
                        lg: "span 7"
                    },
                    display: { xs: 'none', md: 'flex' },
                    paddingBottom: "80px",
                    backgroundPosition: 'center center',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh"
                }}

            >
                <Box textAlign="center">
                    <img src={auth_image} width="90%" />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <Typography
                        variant="display2"
                        align="center"
                    >
                        Lasa grija racordarii pe
                    </Typography>
                    <Typography
                        variant="display2"
                        color="primary"
                    >
                        seama noastra
                    </Typography>
                </Box>

            </Box>
        </Box>
    )
}

export default AuthLayout;