import {SvgIcon} from "@mui/material";

function UserIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 21 20">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0002 8.33317C11.3809 8.33317 12.5002 7.21388 12.5002 5.83317C12.5002 4.45246 11.3809 3.33317 10.0002 3.33317C8.61945 3.33317 7.50016 4.45246 7.50016 5.83317C7.50016 7.21388 8.61945 8.33317 10.0002 8.33317ZM10.0002 9.99984C12.3013 9.99984 14.1668 8.13436 14.1668 5.83317C14.1668 3.53198 12.3013 1.6665 10.0002 1.6665C7.69898 1.6665 5.8335 3.53198 5.8335 5.83317C5.8335 8.13436 7.69898 9.99984 10.0002 9.99984Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.5 13.3332C5.65905 13.3332 4.16667 14.8256 4.16667 16.6665V17.4998C4.16667 17.9601 3.79357 18.3332 3.33333 18.3332C2.8731 18.3332 2.5 17.9601 2.5 17.4998V16.6665C2.5 13.9051 4.73858 11.6665 7.5 11.6665H12.5C15.2614 11.6665 17.5 13.9051 17.5 16.6665V17.4998C17.5 17.9601 17.1269 18.3332 16.6667 18.3332C16.2064 18.3332 15.8333 17.9601 15.8333 17.4998V16.6665C15.8333 14.8256 14.3409 13.3332 12.5 13.3332H7.5Z"/>
        </SvgIcon>
    )
}

export default UserIcon;