import Container from "@mui/material/Container";
import ResponsiveAppBar from "./bars/responsive-navbar";
import {Route, Routes} from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import Footer from "./footer";
import RequestsBar from "./RequestsBar";
import ViewRequestBar from "./bars/view-request-bar";
import ViewRequestSupplierBar from "./bars/view-request-supplier-bar";
import {Alert, AlertTitle, Snackbar} from "@mui/material";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CheckIcon from "@mui/icons-material/Check";
import {createContext, useContext, useState} from "react";
import SupplierHomeBar from "./bars/supplier-home-bar";
import AccountBar from "./bars/account-bar";
import Account from "../pages/Account";

const ResponsiveAppBarSupplier = React.lazy( () => import('./bars/responsive-navbar-supplier'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const DashboardSupplier = React.lazy(() => import('../pages/Dashboard/supplier/supplier'));
const ListRequests = React.lazy(() => import('../pages/Requests'));
const WelcomeBar = React.lazy(() => import('./WelcomeBar'));
const AddRequest = React.lazy(() => import('../pages/AddRequest'));
const AddGasRequest = React.lazy(() => import('../pages/AddRequest/gas'));
const AddElectricityRequest = React.lazy(() => import('../pages/AddRequest/electricity'));
const AddWaterRequest = React.lazy(() => import('../pages/AddRequest/water'));
const ViewRequest = React.lazy(() => import('../pages/ViewRequest'));
const Reports = React.lazy(() => import('../pages/Reports'));
const ReportsNavbar = React.lazy(() => import('./bars/reports-navbar'));

const DateRangeContext = createContext();

export const useDateRangeContext = () => {
    return useContext(DateRangeContext);
};

function AppLayout (props) {
    const [tab, setTab] = useState(0);
    const [request, setRequest] = useState(undefined);
    const [infoOpen, setInfoOpen] = useState(false);
    const [infoType, setInfoType] = useState("success");
    const [infoMessage, setInfoMessage] = useState("");

    //this is used only for reports for now
    const [dateRange, setDateRange] = useState({
       start: new Date(),
       end: new Date()
    });

    const onTabChange = (tab) => {
        setTab(tab);
    }

    const onRequestLoaded = (request) => {
        console.log('onRequestLoaded', request);
        setRequest(request);
    }

    // Add an event listener
    document.addEventListener("showSuccessToast", function(e) {
        console.log(e);
        displayToast(e.detail, true);
    });

    document.addEventListener("showErrorToast", function(e) {
        console.log(e);
        displayToast(e.detail, false);
    });

    function displayToast(message, isSuccess) {
        console.log('display toast');
        let toastType = isSuccess ? "success" : "error";
        setInfoOpen(true);
        setInfoMessage(message);
        setInfoType(toastType);
    }

    return (
        <DateRangeContext.Provider value={{dateRange, setDateRange}}>
            <Box>
                {props.user.userType === "SUPPLIER" ?
                    <ResponsiveAppBarSupplier />
                    :
                    <ResponsiveAppBar />
                }
                {props.user.userType === "CUSTOMER" ?
                    <Routes>
                        <Route exact path="/home" element={<WelcomeBar user={props.user}/>}/>
                        <Route exact path="/requests" element={<RequestsBar user={props.user}/>}/>
                        <Route exact path="/requests/add" element={<></>}/>
                        <Route exact path="/requests/:id" element={<ViewRequestBar user={props.user} request={request}/>}/>
                        <Route exact path="/account" element={<AccountBar />}/>
                    </Routes>
                    :
                    <Routes>
                        <Route exact path="/home" element={<SupplierHomeBar onTabChange={onTabChange} />}/>
                        <Route exact path="/reports" element={<ReportsNavbar />}/>
                        <Route exact path="/requests/:id" element={<ViewRequestSupplierBar request={request}/>}/>
                    </Routes>

                }
                <Container
                    sx = {{
                        paddingTop: {
                            xs: 2,
                            md: 3
                        }
                    }}
                >
                    {props.user.userType === "CUSTOMER" ?
                        <Routes>
                            <Route exact path="/home" element={<Dashboard user={props.user}/>}/>
                            <Route exact path="/requests" element={<ListRequests user={props.user}/>}/>
                            <Route exact path="/requests/:id" element={<ViewRequest onRequestLoaded={onRequestLoaded} />}/>
                            {/*<Route exact path="/requests/:id/edit" element={<EditRequest user={props.user}/>}/>*/}
                            <Route exact path="/requests/add" element={<AddRequest user={props.user}/>}/>
                            {/*<Route exact path="/requests/add/gas" element={<AddGasRequest user={props.user}/>}/>*/}
                            <Route exact path="/requests/:id/gas/edit/*" element={<AddGasRequest edit />}/>
                            <Route exact path="/requests/add/gas/*" element={<AddGasRequest />}/>

                            <Route exact path="/requests/:id/electricity/edit/*" element={<AddElectricityRequest edit />}/>
                            <Route exact path="/requests/add/electricity/*" element={<AddElectricityRequest />}/>

                            <Route exact path="/requests/:id/water/edit/*" element={<AddWaterRequest edit />}/>
                            <Route exact path="/requests/add/water/*" element={<AddWaterRequest />}/>

                            <Route exact path="/account" element={<Account />}/>
                        </Routes>
                        :
                        <Routes>
                            <Route exact path="/home" element={<DashboardSupplier tab={tab}/>}/>
                            <Route exact path="/requests/:id" element={<ViewRequest onRequestLoaded={onRequestLoaded} />}/>
                            <Route exact path="/reports" element={<Reports />}/>
                        </Routes>
                    }
                </Container>
                <Footer />

                <Snackbar
                    open={infoOpen}
                    autoHideDuration={5000}
                    onClose={() => setInfoOpen(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert
                        variant="filled"
                        severity={infoType}
                        icon={infoType === "error" ? <DoDisturbAltIcon fontSize="inherit" /> : <CheckIcon fontSize="inherit" />}
                        sx={{
                            marginBottom: 2
                        }}
                    >
                        <AlertTitle>{infoType === "error" ? "Eroare" : "Success"}</AlertTitle>
                        {infoMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </DateRangeContext.Provider>
    )
}

export default AppLayout;