import {AutoWidthSvg} from "../../../components/StylizedComponents";

function LandingPageDialogIcon(props) {
    return (
        <AutoWidthSvg {...props} viewBox="0 0 40 40">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.6666 15C11.6666 14.0796 12.4128 13.3334 13.3333 13.3334H26.6666C27.5871 13.3334 28.3333 14.0796 28.3333 15C28.3333 15.9205 27.5871 16.6667 26.6666 16.6667H13.3333C12.4128 16.6667 11.6666 15.9205 11.6666 15Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.6666 21.6667C11.6666 20.7462 12.4128 20 13.3333 20H20C20.9204 20 21.6666 20.7462 21.6666 21.6667C21.6666 22.5871 20.9204 23.3333 20 23.3333H13.3333C12.4128 23.3333 11.6666 22.5871 11.6666 21.6667Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.1994 29.1059C11.7985 28.6067 12.5536 28.3333 13.3334 28.3333H31.6667C32.5872 28.3333 33.3334 27.5871 33.3334 26.6667V10C33.3334 9.07952 32.5872 8.33333 31.6667 8.33333H8.33337C7.4129 8.33333 6.66671 9.07953 6.66671 10V32.8832L11.1994 29.1059ZM13.3334 31.6667H31.6667C34.4281 31.6667 36.6667 29.4281 36.6667 26.6667V10C36.6667 7.23858 34.4281 5 31.6667 5H8.33337C5.57195 5 3.33337 7.23858 3.33337 10V32.8832C3.33337 35.7093 6.62956 37.2532 8.80065 35.4439L13.3334 31.6667Z" />
        </AutoWidthSvg>
    )
}

export default LandingPageDialogIcon;