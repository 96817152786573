import {SvgIcon} from "@mui/material";

function Linkedin(props) {
    return (
        <SvgIcon
            {...props}
            width={props.width ? props.widht : "24"}
            height={props.height ? props.height : "25"}
            viewBox={props.viewport ? props.viewport : "0 0 24 25"}
        >
            <path d="M12.7627 11.7011V11.6699C12.7562 11.6804 12.7493 11.691 12.7424 11.7011H12.7627Z" fill="#87879A"/>
            <path
                d="M20.6146 2.52441H3.3844C2.5597 2.52441 1.89087 3.1773 1.89087 3.98194V21.4138C1.89087 22.218 2.5597 22.8709 3.3844 22.8709H20.6146C21.4405 22.8709 22.1093 22.2176 22.1093 21.4138V3.98194C22.1093 3.17689 21.4401 2.52441 20.6146 2.52441ZM8.0192 19.557H4.96547V10.3696H8.0192V19.557ZM6.49254 9.11459H6.47208C5.44776 9.11459 4.78506 8.40894 4.78506 7.52738C4.78506 6.62619 5.46822 5.94018 6.51258 5.94018C7.55736 5.94018 8.20001 6.62619 8.22046 7.52738C8.22046 8.40894 7.55695 9.11459 6.49254 9.11459ZM19.031 19.557H15.9773V14.6408C15.9773 13.4062 15.5355 12.5635 14.4306 12.5635C13.5871 12.5635 13.0848 13.1317 12.8634 13.6807C12.7829 13.8766 12.7632 14.1507 12.7632 14.4256V19.5566H9.70949C9.70949 19.5566 9.74958 11.2303 9.70949 10.3692H12.7632V11.6697C13.169 11.0438 13.8947 10.1524 15.5159 10.1524C17.5248 10.1524 19.0315 11.4659 19.0315 14.2881L19.031 19.557Z"
                fill="#87879A"/>

        </SvgIcon>
    )
}

export default Linkedin;