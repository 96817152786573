import * as React from 'react';
import {Alert, Collapse, FormControl, FormLabel, InputAdornment, Link, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import logo from "../images/logo_new.png";
import LoadingButton from "@mui/lab/LoadingButton";
import {AlertTitle} from "@mui/lab";
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import {StylizedInput} from "../components/StylizedComponents";
import * as yup from "yup";
import {useFormik} from "formik";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Auth2 from "./Auth2";

const validationSchema = yup.object({
    email: yup
        .string('Introduce email')
        .email('Emailul nu este valid')
        .required('Completeaza email'),
    password: yup
        .string('Introduce-ti parola')
        .min(8, 'Parola trebuie sa aiba mai mult de 8 caractere')
        .required('Completeaza parola')
})

function SignIn(props) {

    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            console.log('signin');
            //Auth2.signIn(values.email, values.password);

            Auth2.signIn(values.email, values.password)
            .then(user => {
                showSuccess("Autentificare reusita!");
                setLoading(false);
                navigate("/app/home");
            }).catch(err => {
                console.log('gg', err);
                setLoading(false);
                showError("Autentificarea a esuat!");
            });
        }
    });

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const [infoOpen, setInfoOpen] = useState(false);
    const [infoType, setInfoType] = useState("error");
    const [infoMessage, setInfoMessage] = useState("");

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function showError(message) {
        setInfoType("error");
        setInfoMessage(message);
        setInfoOpen(true);
    }

    function showSuccess(message) {
        setInfoType("success");
        setInfoMessage(message);
        setInfoOpen(true);
    }

    function handleSubmit(e) {
        formik.handleSubmit(e);
        return false;
    }

    function goToPasswordReset() {
        navigate("/auth/password-reset");
    }

    function goToSignUp() {
        navigate("/auth/sign-up");
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx = {{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "400px"
            }}
        >
            <Box sx={{
                textAlign: "left",
                width: "100%",
                marginBottom: 4
            }}>
                <img src={logo} alt="TedConect logo" />
            </Box>
            <Typography
                variant="h2"
                sx = {{
                    marginBottom: 4
                }}
            >
                Autentificare
            </Typography>
            <Collapse in={infoOpen}>
                <Alert
                    variant="filled"
                    severity={infoType}
                    icon={infoType === "error" ? <DoDisturbAltIcon fontSize="inherit" /> : <CheckIcon fontSize="inherit" />}
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <AlertTitle>{infoType === "error" ? "Eroare" : "Success"}</AlertTitle>
                    {infoMessage}
                </Alert>
            </Collapse>
            <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 2,
                    width: "100%"
                }}
            >
                <FormLabel htmlFor="login-email">
                    <Typography variant="caption">
                        Email
                    </Typography>
                </FormLabel>
                <StylizedInput
                    placeholder="Introduce-ti adresa de email"
                    id="login-email"
                    name="email"
                    value={formik.values.email || ""}
                    onChange={formik.handleChange}
                    error={(formik.touched.email || formik.submitCount > 0) && Boolean(formik.errors.email)}
                    helperText={(formik.touched.email || formik.submitCount > 0) && formik.errors.email}
                />
            </FormControl>

            <FormControl
                variant="standard"
                sx = {{
                    marginBottom: 2
                }}
            >
                <FormLabel shrink htmlFor="login-password">
                    <Typography variant="caption2">
                        Parola
                    </Typography>
                </FormLabel>
                <StylizedInput
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Introduce-ti parola"
                    id="login-password"
                    name="password"
                    value={formik.values.password || ""}
                    onChange={formik.handleChange}
                    error={(formik.touched.password || formik.submitCount > 0) && Boolean(formik.errors.password)}
                    helperText={(formik.touched.password || formik.submitCount > 0) && formik.errors.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}

                />
            </FormControl>

            <Box sx = {{
                textAlign: "right",
                marginBottom: 4
            }}>
                <Link variant="subtitle2" onClick={goToPasswordReset}>
                    Am uitat parola
                </Link>
            </Box>
            <Box sx={{
                marginBottom: 3
            }}>
                <LoadingButton
                    loading={loading}
                    type="submit"
                    variant="contained"
                    sx= {{
                        width: "100%"
                    }}
                >
                    Intra in cont
                </LoadingButton>
            </Box>
            <Box sx={{
                textAlign: "center"
            }}>
                Nu ai cont? <Link variant="subtitle2" onClick={goToSignUp}>Creeaza acum</Link>
            </Box>

        </Box>
    )
}

export default SignIn;