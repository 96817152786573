import {AutoWidthSvg} from "../../../components/StylizedComponents";

function LandingPageTargetIcon(props) {
    return (
        <AutoWidthSvg {...props} viewBox="0 0 40 40">
            <path fillRule="evenodd" clipRule="evenodd" d="M23.3333 13.3333H16.6666C15.7462 13.3333 15 14.0795 15 15V25C15 25.9205 15.7462 26.6667 16.6666 26.6667H23.3333C24.2538 26.6667 25 25.9205 25 25V15C25 14.0795 24.2538 13.3333 23.3333 13.3333ZM16.6666 10C13.9052 10 11.6666 12.2386 11.6666 15V25C11.6666 27.7614 13.9052 30 16.6666 30H23.3333C26.0947 30 28.3333 27.7614 28.3333 25V15C28.3333 12.2386 26.0947 10 23.3333 10H16.6666Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.33337 8.33331C3.33337 5.57189 5.57195 3.33331 8.33337 3.33331H11.6667C12.5872 3.33331 13.3334 4.07951 13.3334 4.99998C13.3334 5.92045 12.5872 6.66665 11.6667 6.66665H8.33337C7.4129 6.66665 6.66671 7.41284 6.66671 8.33331V11.6666C6.66671 12.5871 5.92052 13.3333 5.00004 13.3333C4.07957 13.3333 3.33337 12.5871 3.33337 11.6666V8.33331Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.33337 31.6667C3.33337 34.4281 5.57195 36.6667 8.33337 36.6667H11.6667C12.5872 36.6667 13.3334 35.9205 13.3334 35C13.3334 34.0795 12.5872 33.3334 11.6667 33.3334H8.33337C7.4129 33.3334 6.66671 32.5872 6.66671 31.6667V28.3334C6.66671 27.4129 5.92052 26.6667 5.00004 26.6667C4.07957 26.6667 3.33337 27.4129 3.33337 28.3334V31.6667Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M36.6666 31.6667C36.6666 34.4281 34.428 36.6667 31.6666 36.6667H28.3333C27.4128 36.6667 26.6666 35.9205 26.6666 35C26.6666 34.0795 27.4128 33.3334 28.3333 33.3334H31.6666C32.5871 33.3334 33.3333 32.5872 33.3333 31.6667V28.3334C33.3333 27.4129 34.0795 26.6667 35 26.6667C35.9204 26.6667 36.6666 27.4129 36.6666 28.3334V31.6667Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M36.6666 8.33331C36.6666 5.57189 34.428 3.33331 31.6666 3.33331H28.3333C27.4128 3.33331 26.6666 4.07951 26.6666 4.99998C26.6666 5.92045 27.4128 6.66665 28.3333 6.66665H31.6666C32.5871 6.66665 33.3333 7.41284 33.3333 8.33331V11.6666C33.3333 12.5871 34.0795 13.3333 35 13.3333C35.9204 13.3333 36.6666 12.5871 36.6666 11.6666V8.33331Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.33337 20C3.33337 19.0795 4.07957 18.3333 5.00004 18.3333H35C35.9205 18.3333 36.6667 19.0795 36.6667 20C36.6667 20.9205 35.9205 21.6666 35 21.6666H5.00004C4.07957 21.6666 3.33337 20.9205 3.33337 20Z"/>
        </AutoWidthSvg>
    )
}

export default LandingPageTargetIcon;