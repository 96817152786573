import WebsiteNavbar from "./navbar";
import {Route, Routes} from "react-router-dom";
import Main from "./main";
import React from "react";
import Box from "@mui/material/Box";
import {GlobalStyles} from "@mui/material";

const ForSuppliers = React.lazy( () => import('./for-suppliers'));
const Contact = React.lazy( () => import('./contact'));
const TermsConditions = React.lazy( () => import('./terms-conditions'));
const PrivacyPolicy = React.lazy( () => import('./privacy-policy'));
const AskDemo = React.lazy( () => import('./ask-demo'));

function Website() {
    return (
        <Box sx = {{paddingTop: 3 }}>
            <GlobalStyles
                styles={{
                    body: { backgroundColor: "#FFFFFF" },
                }}
            />
            <WebsiteNavbar />
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/pentru-furnizori" element={<ForSuppliers />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/termeni-si-conditii" element={<TermsConditions />} />
                <Route path="/politica-de-confidentialitate" element={<PrivacyPolicy />} />
                <Route path="/cere-demo" element={<AskDemo />} />
            </Routes>
        </Box>
    )
}

export default Website;